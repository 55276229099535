import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useRootProvider} from 'providers/RootContext';
import {useTheme} from '@mui/styles';
import {Box, Theme, Typography} from '@mui/material';
import {DoneRounded as Done} from '@mui/icons-material';
import {SpacerV, YCenter,} from 'components/common';

export const useStyles: Function = (theme: Theme) => ({
  root: {
    boxShadow: 'none'
  },
  first: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 200,
    height: 200,
    borderRadius: 200,
    background: theme.palette.primaryLight.light
  },
  second: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 150,
    height: 150,
    borderRadius: 150,
    background: theme.palette.primaryLight.main
  },
  third: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 100,
    borderRadius: 100,
    background: theme.palette.primaryLight.dark
  },
  content: {
    padding: 2
  }
});

const Success = observer(() => {
  const {campaignProvider: cp, wizardProvider: wp} = useRootProvider();

  const sx = useStyles(useTheme());

  useEffect(() => {
    wp.setOnNext(async () => {
      cp.finish();
    });
  }, [wp, cp]);

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "space-between",
      height: '100%',
    }}>
      <SpacerV size={"10"}/>
      <Box sx={sx.first}>
        <Box sx={sx.second}>
          <Box sx={sx.third}>
            <Done sx={{color: '#ffffff', height: 60, width: 60, fontWeight: 900}}/>
          </Box>
        </Box>
      </Box>
      <SpacerV size={"10"}/>
      <Typography variant="h3" color="primary">
        Success
      </Typography>
      <Typography align="center" variant="body1">
        Your campaign has been created and is in Draft status. Campaign can be edited until you publish it. Once the
        campaign is Published it
        can not be edited.
      </Typography>
      <SpacerV size={"10"}/>
    </div>
  );
})

export default Success;


import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useRootProvider} from 'providers/RootContext';
import {useParams} from "react-router-dom";
import {Box, CircularProgress, Typography} from "@mui/material";
import {
  InfoPanel,
  InfoPanelItem,
  SpacerV,
  YButtonPrimary,
  YButtonSecondary, YCenter,
  YTextField
} from "../../../components/common";
import {useForm} from "react-hook-form";
import Api from "../../../api/api";
import {TOAST_MESSAGE_TYPE} from "../../../providers/ToastProvider";

export const Menu = observer(() => {
  const {businessProvider: bp, drawerProvider: dp, toastProvider: tp} = useRootProvider();
  let {id} = useParams();
  const [location, setLocation] = useState<any>();
  const [catalogs, setCatalogs] = useState<any[]>([]);

  const getCatalog = () => {
    if (catalogs){
      return catalogs[0];
    }

    return undefined;
  }

  useEffect(() => {
    if (bp.locations === undefined) {
      bp.getLocations();
    }
  }, [bp]);


  useEffect(() => {
    const fetchData = async () => {
      if (bp) {
        const loc = bp.locations?.find((l: any) => l.id === id);
        setLocation(loc);
        if (loc) {
          const resp = await Api.catalog.getLocationCatalogs(loc.id);
          const catalogs = resp?.data;
          setCatalogs(catalogs);
        }
      }
    };

    fetchData();
  }, [bp, bp.locations]);

  /*const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleProviderTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProviderToken(event.target.value);
  };*/

  const form = useForm({mode: 'onChange'});

  const updateMenu = async () => {
    try {
      const res = await form.handleSubmit(async () => {
        let resp = await Api.catalog.addMenuToAccount(bp.business!.id, {
          name: form.getValues().name,
          providerId: form.getValues().providerToken,
          provider: 'chowly',
          locationId: id,
        });

        setCatalogs([resp.data])
        dp.close();
      })();
    } catch (e: any | Error) {
      console.log('error updating menu: ', e);
      const msg = e?.response?.data?.error?.message || e.message;
      tp.showMessage(msg, TOAST_MESSAGE_TYPE.ERROR, 6000);
    }
  }

  const getDrawerContent = (detaultData: any) => {
    const onAction = location ? bp.updateLocation : bp.createLocation;

    form.setValue('name', detaultData?.name);
    form.setValue('providerToken', detaultData?.providerId);

    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <div>
          <YTextField
            fullWidth
            name="name"
            label="Name"
            control={form.control}
            isRequired
          />
          <YTextField
            fullWidth
            name="providerToken"
            label="Provider Token"
            control={form.control}
            isRequired
          />
        </div>
        <Box>
          <YButtonPrimary
            fullWidth
            onPress={updateMenu}
          >
            Save
          </YButtonPrimary>
        </Box>
      </Box>
    );
  };

  const editLocation = () => dp.drawerOpen(getDrawerContent(getCatalog()), 'Update Menu');

  return (
    <div style={{width: "100%", display: "flex"}}>
      <div style={{width: '48%', marginRight: '1em'}}>
        <div style={{marginBottom: '1em'}}>
          {
            bp.isFetching && <YCenter><CircularProgress/></YCenter>
          }
          {
            !bp.isFetching && catalogs && catalogs.map((catalog, index) => (
              <InfoPanel key={index} title={"Menu"} onEdit={editLocation}>
                <InfoPanelItem label={"Name"}>{catalog.name || ''}</InfoPanelItem>
                <InfoPanelItem label={"Provider token"}>{catalog.providerId || ''}</InfoPanelItem>
              </InfoPanel>
            ))
          }
          {!bp.isFetching && (!catalogs || catalogs.length === 0) &&
            <div style={{marginLeft: '2em', marginTop: '2em'}}>
              <Typography variant="h6">No menu added</Typography>
              <SpacerV size="30"/>
              <YButtonSecondary onPress={editLocation}>Add menu</YButtonSecondary>
            </div>
          }
        </div>
      </div>
      <div style={{width: '48%'}}>
        {/* Add other panels as needed */}
      </div>
    </div>
  );
});

import { makeAutoObservable } from 'mobx';
import RootProvider from './RootProvider';

const TOAST_DURATION = 3000;

export enum TOAST_MESSAGE_TYPE {
  INFO,
  SUCCESS,
  ERROR,
}

class ToastProvider {
  root: RootProvider;
  public types = TOAST_MESSAGE_TYPE;

  public open = false;
  public message = '';
  public duration = 6000;
  type?: TOAST_MESSAGE_TYPE = undefined;

  constructor(root: RootProvider) {
    this.root = root;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  hasMessage = () => {
    return this.message !== '';
  }

  showMessage(message: string, type: TOAST_MESSAGE_TYPE = TOAST_MESSAGE_TYPE.INFO, duration?: number) {
    this.message = message;
    this.type = type;
    this.open = true;
    this.duration = duration === undefined ? TOAST_DURATION : duration;
    console.log('duration', this.duration);
    //window.setTimeout(() => { this.message = ''; }, this.duration);
  }

  clearMessage() {
    this.open = false;
    this.message = '';
  }

  showNotImplemented() {
    this.showMessage('Not implemented yet', TOAST_MESSAGE_TYPE.INFO);
  }
}

export default ToastProvider;

import React from 'react';
import {
  Avatar,
  ButtonBase,
  MenuItem,
  Menu,
  Theme,
  Box
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/styles';
import PersonIcon from '@mui/icons-material/Person';

interface Props {
  name: string;
  onLogout: React.MouseEventHandler;
}

const useStyles: Function = (theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    marginTop: 0.5,
    color: theme.palette.text.secondary
  }
});

const AccountMenu: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const [initials, setInitials] = React.useState<string>('');

  const getInitials = (name: string) => {
    return name.split(' ').map(part => part.charAt(0).toUpperCase()).join('');
  };

  React.useEffect(() => {
    setInitials(getInitials(props.name));
  }, [props.name]);

  const classes = useStyles(useTheme());

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMyAccount = () => {
    navigate("/me", {replace: true});
  }

  return (
    <Box sx={classes.root}>
      <ButtonBase onClick={handleToggle}>
        {initials ? (
          <Avatar alt={props.name}>{initials}</Avatar>
        ) : (
          <Avatar>
            <PersonIcon />
          </Avatar>
        )}
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        open={open}
        onClose={handleClose}
        PaperProps={{style: {width: '20ch'}}}
      >
        <MenuItem onClick={props.onLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default AccountMenu;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { YTextField, YButtonPrimary, Uploader, SpacerV } from 'components/common';
import { useRootProvider } from 'providers/RootContext';

type FormValues = {
  name: string;
  logo: File | undefined;
};

const CreateBrand = observer(() => {
  const { businessProvider: bp } = useRootProvider();

  const { control, handleSubmit, setValue, trigger, watch } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { name: '', logo: undefined },
  });

  const fields = watch();

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <div>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <YTextField control={control} fullWidth label="Brand Name" {...field} isRequired />}
        />

        <SpacerV size="30" />
        <Typography variant="caption">
          Please upload your brand logo. This logo will be shown to all the users browsing your campaigns.
          Logo size should be 500px x 500px.
        </Typography>
        <SpacerV size="10" />

        <Uploader isImage onSelect={(file: File) => {
          setValue('logo', file);
          console.log('clicked on logo');
          bp.setBrandLogo(file);
          trigger('logo');
        }} />
      </div>

      <Box>
        <YButtonPrimary
          fullWidth
          onPress={handleSubmit(bp.createBrand)}
          loading={bp.isFetching}
          disabled={!fields.name || !fields.logo}
        >
          Save
        </YButtonPrimary>
      </Box>
    </Box>
  );
});

export default CreateBrand;

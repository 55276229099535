import React from 'react';
import { useTheme } from '@mui/styles';
import { Theme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import {useLocation } from 'react-router-dom';
import {
	AppBar,
  Toolbar,
  Typography,
  Box
} from '@mui/material';
import { East } from '@mui/icons-material';
import TopBarSearch from './TopBarSearch';
import Notifications from './Notifications';
import AccountMenu from 'components/AccountMenu';
import { capitalize } from 'utils/strings';
import { useRootProvider } from 'providers/RootContext';
import {toJS} from "mobx";

const drawerWidth = 300;

const useStyles: Function = (theme: Theme) => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    background: theme.palette.common.white,
    color: theme.palette.text.secondary
  },
  title: {
    flexGrow: 1,
    fontSize: 18
  },
  icon: {
    width: 16,
    height: 16,
    verticalAlign:'middle',
    marginLeft: 1,
    marginRight: 1
  },
  user: {
    display: 'flex'
  },
  name: {
    color: theme.palette.text.primary,
    fontSize: 16
  },
  toolbar: theme.mixins.toolbar,
  textPrimary: {
    color: theme.palette.text.primary,
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: theme.palette.grey[200]
  }
});

interface Props {
  onSearch?: Function;
  onListNotifications?: Function;
}

const TopBar = observer((props: Props) => {
  const styles = useStyles(useTheme());
  const location = useLocation();
  const { userProvider } = useRootProvider();

  const title = () => {
    const segments = location.pathname.substring(1).split('/');
    return segments.map((s, i, {length}) => {
      if (s.length > 20)
        return <span key={s}/>

      return <span key={s}>
          {capitalize(s)}
          {i + 1 !== length && <East sx={styles.icon} />}
        </span>
    });
  }

  return (
    <AppBar elevation={0} position="fixed" sx={styles.appBar}>
      <Toolbar>
        <Typography sx={styles.title} variant="h6" noWrap>
          {title()}
        </Typography>

        {props.onSearch && <TopBarSearch /> }

        {props.onListNotifications && <Notifications /> }

        <Box width={5} height={60} sx={styles.dividerWrapper}>
        </Box>

        <AccountMenu
          name={userProvider.fullName()}
          onLogout={userProvider.logout}
        />
      </Toolbar>
    </AppBar>
  );
});

export default TopBar;

class UsersMock {
  static listUsers = () => {
    return [
      {
        id: '1',
        firstName: "Tony",
        lastName: "Stark",
        email: "ironman@marvel.com",
        phone: "+15553030203"
      },
      {
        id: '2',
        firstName: "Bruce",
        lastName: "Banner",
        email: "hulk@marvel.com",
        phone: "+15553030203"
      },
      {
        id: '3',
        firstName: "Thor",
        lastName: "Odinson",
        email: "thor@marvel.com",
        phone: "+15553030203"
      },
      {
        id: '4',
        firstName: "Natalia",
        lastName: "Romanov",
        email: "blackwidow@marvel.com",
        phone: "+15553030203"
      }
    ]
  }

}

export default UsersMock;


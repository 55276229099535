import React from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import {
  Grid,
  Box
} from '@mui/material';
import {
  Email,
  Instagram,
  Twitter,
  Facebook
} from '@mui/icons-material';
import SpacerV from 'components/common/SpacerV';
import logoDark from 'images/logoDark.svg';
import {ReactComponent as Logo} from 'images/white_logo.svg';

const useStyles: Function = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100vh',
    display: 'flex',
    background: theme.palette.background.default
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 50,
    width: '100%'
  },
  icon: {
    color: theme.palette.grey[900]
  },
  drawerPaper: {
    width: '100vh',
    height: '100vh',
    background: theme.palette.grey[900]
    //background: `linear-gradient(to bottom, ${theme.palette.grey[900]}, ${theme.palette.grey[900]})`
  },
  footer: {
    marginTop: 30,
    fontSize: 16,
    color: theme.palette.grey[900]
  }
}));

interface Props {
  children?: React.ReactNode;
}

const SignupLayout: React.FC<Props> = props => {

    const  classes = useStyles();
    const { children } = props;
    return (
      <div className={classes.root}>

        <Grid container spacing={0} direction="row" >
          <Grid item sm={6}>
            <Box sx={{padding: 2}}>
              <Logo height={64} width={150} fill="#6667F6"  />
            </Box>
            <Grid container direction="column" alignItems="center" justifyContent="flex-start">
              <Grid item>
              </Grid>

              <Grid item>
                <div className={classes.content}>
                  {children}
                  <Outlet />
                </div>
              </Grid>

              <Grid item>
                <div className={classes.footer}>
                  &copy; 2021 Yuzzle Inc. All rights reserved.
                  <SpacerV />
                  <Grid container direction="row" justifyContent="space-evenly">
                    <Grid item>
                      <Email className={classes.icon} />
                    </Grid>
                    <Grid item>
                      <Instagram className={classes.icon} />
                    </Grid>
                    <Grid item>
                      <Twitter className={classes.icon} />
                    </Grid>
                    <Grid item>
                      <Facebook className={classes.icon} />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

            </Grid>
          </Grid>

          <Grid item sm={6} sx={{margin: 0, padding: 0}}>
            <Box className={classes.drawerPaper}>
            </Box>
          </Grid>


        </Grid>
      </div>
    );
  }

export default SignupLayout;

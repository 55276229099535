import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useRootProvider } from 'providers/RootContext';
import { useForm, Controller } from 'react-hook-form';
import {
  Typography
} from '@mui/material';
import InputMask from 'react-input-mask';
import SpacerV from 'components/common/SpacerV';
import YTextField from 'components/common/YTextField';
import YButtonPrimary from 'components/common/YButtonPrimary';
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    textAlign: 'center'
  },
  title: {
    color: theme.palette.text.secondary,
  }
}));

interface Props {}

function PersonalInfo(props: Props) {
  const classes = useStyles();
  const { userProvider } = useRootProvider();

  const defaultValues = {
    name: '',
    familyName: '',
    phone: ''
  }

  const { handleSubmit, control } = useForm({ defaultValues });

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Personal Info
      </Typography>
      <SpacerV size="small" />

      <Typography variant="body1" >
        We just need the most basic personal info.
      </Typography>
      <SpacerV />

      <YTextField
        fullWidth
        label="First Name"
        name="name"
        control={control}
        isRequired
      />

      <YTextField
        fullWidth
        label="Last Name"
        name="familyName"
        control={control}
        isRequired
      />

      <Controller
        name="phone"
        control={control}
        defaultValue=""
        rules={{
          validate: value => value.replace(/[^\d]/g, '').length === 11 || 'Please enter a full phone number.',
        }}
        render={({ field }) => (
          <InputMask mask="+1(999) 999-9999" value={field.value} onChange={field.onChange}>
            {
              // @ts-ignore
              (inputProps: any) => <YTextField {...inputProps} control={control} fullWidth label="Phone" name="phone" isRequired />
            }
          </InputMask>
        )}
      />

      <SpacerV size="small"/>

      <YButtonPrimary fullWidth onPress={handleSubmit(userProvider.savePersonalInfo)} >
        Save
      </YButtonPrimary>

      <SpacerV size="large"/>

    </div>
  );
}

export default PersonalInfo;

import { makeAutoObservable } from 'mobx';
import RootProvider from './RootProvider';


class TutorialProvider {

  root: RootProvider;

  private showBrandsButton: boolean|undefined = false;
  private showLocationsButton: boolean|undefined = false;
  private showCampaignButton: boolean = false;

  public get brandsButton() {
    return this.showBrandsButton;
  }

  public get locationsButton() {
    return this.showLocationsButton;
  }

  public get campaignsButton() {
    return this.showCampaignButton;
  }

  private canShowBrandsButton = () => {
    this.showBrandsButton =
      !this.root.businessProvider.isFetching &&
      this.root.businessProvider.brands &&
      this.root.businessProvider.brands.length === 0
  }

  private canShowLocationButton = () => {
    this.showLocationsButton =
      !this.root.businessProvider.isFetching &&
      this.root.businessProvider.brands &&
      this.root.businessProvider.brands.length > 0 &&
      this.root.businessProvider.locations &&
      this.root.businessProvider.locations.length === 0;
  }

  private canShowCampaignsButton = () => {
    this.showCampaignButton =  Boolean(
      !this.root.businessProvider.isFetching &&
      !this.root.campaignProvider.fetching &&
      this.root.businessProvider.brands &&
      this.root.businessProvider.brands.length > 0 &&
      this.root.businessProvider.locations &&
      this.root.businessProvider.locations.length > 0 &&
      this.root.campaignProvider.campaigns.length === 0
    ).valueOf();
  }

  public inspect = () => {
    this.canShowBrandsButton();
    this.canShowLocationButton();
    this.canShowCampaignsButton();
  }

  constructor(root: RootProvider) {
    this.root = root;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}

export default TutorialProvider;


import React from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import {useRootProvider} from "../../providers/RootContext";
import {Box, MenuItem} from "@mui/material";
import {YButtonPrimary, YTextField} from "../../components/common";
import {useForm} from "react-hook-form";
import {TOAST_MESSAGE_TYPE} from "../../providers/ToastProvider";
import Api from "../../api/api";


export const ConnectMenu = observer(() => {
  const { businessProvider: bp, toastProvider: tp, drawerProvider: dp } = useRootProvider();
  const menuBrands = toJS(bp.brands) ?? [];
  const form = useForm({mode: 'onChange'});

  console.log('menuBrands', menuBrands);

  const updateMenu = async () => {
    try {
      const res = await form.handleSubmit(async () => {
        let resp = await Api.catalog.addMenuToAccount(bp.business!.id, {
          name: form.getValues().name,
          providerId: form.getValues().providerToken,
          provider: 'chowly',
          locationId: form.getValues().locationId,
        });

        bp.getLocations();
        dp.close();
      })();
    } catch (e: any | Error) {
      console.log('error updating menu: ', e);
      const msg = e?.response?.data?.error?.message || e.message;
      tp.showMessage(msg, TOAST_MESSAGE_TYPE.ERROR, 6000);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <div>
        <YTextField
          fullWidth
          select
          name="locationId"
          label="Select Location"
          control={form.control}
          isRequired
        >
          {bp.locations?.map(loc => (
            <MenuItem key={loc.id} value={loc.id}>{loc.name}</MenuItem>
          ))}
        </YTextField>
        <YTextField
          fullWidth
          name="name"
          label="Name"
          control={form.control}
          isRequired
        />
        <YTextField
          fullWidth
          name="providerToken"
          label="Provider Token"
          control={form.control}
          isRequired
        />
      </div>
      <Box>
        <YButtonPrimary
          fullWidth
          onPress={updateMenu}
        >
          Save
        </YButtonPrimary>
      </Box>
    </Box>
  );
});

class Amount {
  public value: number;
  public currency: string;
  public formatted: string;

  public constructor(data: any) {
    if (!data) {
      this.value = 0;
      this.currency = '';
      this.formatted = '';
    } else {
      this.value = data.value || 0;
      this.currency = data.currency || '';
      this.formatted = data.formatted || '';
    }
  }
}

export default Amount;



import { makeAutoObservable } from 'mobx';
import { AxiosError } from 'axios';
import RootProvider from './RootProvider';
import { TOAST_MESSAGE_TYPE } from './ToastProvider';

interface ApiError {
  error: Error;
}

class ErrorProvider {
  root: RootProvider;

  code?: number | string;
  message?: string;

  public checkApiError = (error: AxiosError, callback?: Function | null) => {
    if (error && error.isAxiosError) {
      const { status, data } = error.response!;
      if (status === 401) {
        console.log('unauthorized');
        if (this.root.userProvider.tokenExpired) {
          this.root.userProvider.refreshToken().then();
        }
      } else if (status === 403) {
        console.log('forbidden')
        this.root.toastProvider.showMessage(`Api Error: Forbidden`, TOAST_MESSAGE_TYPE.ERROR);
      } else {
        let err = data as ApiError;
        if (err?.error?.message) {
          this.message = err?.error?.message;
          this.root.toastProvider.showMessage(`Api Error: ${this.message}`, TOAST_MESSAGE_TYPE.ERROR);
        }
      //toastStore.showMessage(`Api error: ${errorMessage}`, TOAST_MESSAGE_TYPE.ERROR);
      }
    }

    if (callback) {
      callback();
    }
  }

  constructor(root: RootProvider) {
    this.root = root;

    makeAutoObservable(this, {}, { autoBind: true });
  }
}

export default ErrorProvider;


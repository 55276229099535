import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootProvider } from 'providers/RootContext';
import { Box, Theme, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import InfoPanel from "components/common/InfoPanel";
import { createStyles, makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import CreateLocation from "./CreateLocation";
import {InfoPanelItem} from "../../../components/common";
import {toJS} from "mobx";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export const Details = observer(() => {
  const { businessProvider: bp, drawerProvider: dp } = useRootProvider();
  const classes = useStyles();
  let { id } = useParams();
  const [location, setLocation] = useState<any>();

  useEffect(() => {
    bp.getLocations();
  }, [bp]);

  useEffect(() => {
    const loc = bp?.locations?.find((l: any) => l.id === id);
    setLocation(loc);
  }, [id, bp.locations]);

  const { setValue, ...form } = useForm({ defaultValues: {}, mode: 'onChange' });

  useEffect(() => {
    if (location) {
      form.reset({ ...location.settings, ...location.brand, ...location });
    }
  }, [location]);

  const getDrawerContent = () => {
    const onAction = location ? bp.updateLocation : bp.createLocation;
    return <CreateLocation form={form} setValue={setValue} onAction={onAction} initialAddress={location?.address} />;
  };

  const editLocation = () => dp.drawerOpen(getDrawerContent(), 'Update Location');

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: '48%', marginRight: '1em' }}>
        <div style={{ marginBottom: '1em' }}>
          <InfoPanel title={"Location info"} onEdit={editLocation}>
            <InfoPanelItem label={"Address"}>{location?.address || ''}</InfoPanelItem>
            <InfoPanelItem label={"Brand ID"}>{location?.brandId || ''}</InfoPanelItem>
            <InfoPanelItem label={"Email"}>{location?.email || ''}</InfoPanelItem>
            <InfoPanelItem label={"Name"}>{location?.name || ''}</InfoPanelItem>
          </InfoPanel>
        </div>
      </div>
    </div>
  );
});

import React from 'react';
import {createStyles, makeStyles} from '@mui/styles';
import {Divider, Theme, Typography, Button} from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepContainer: {
      display: 'flex',
      alignItems: 'start',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    stepLeftContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: theme.spacing(6),
      position: 'relative',
      height: '100%',
    },
    stepRightContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      maxWidth: '600px',
      marginTop: 8,
    },
    divider: {
      alignSelf: 'stretch',
      width: '1px',
      minHeight: theme.spacing(10),
      backgroundColor: '#0000001A',
      marginTop: theme.spacing(6),
    },
    stepNumber: {
      zIndex: 1,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: '50%',
      width: theme.spacing(4),
      height: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: theme.spacing(1), // Adjust as needed
      left: '50%',
      transform: 'translateX(-50%)',
    },
    stepTitle: {
      fontWeight: 500,
    },
    stepDescription: {
      marginTop: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
      width: theme.spacing(24),
    },
  })
);

interface Props {
  callbacks: Array<any>;
  activeStepIndex: number;
}

export const DashboardWizard = (props: Props) => {
  const classes = useStyles();
  let {callbacks, activeStepIndex} = props;

  const stepsConfig = [
    {
      title: 'Setup brand',
      description: 'Begin by giving your restaurant brand a name and identity. ' +
        'This is the cornerstone of your business\'s online presence. ' +
        'Provide essential details like your brand\'s name and logo.',
      buttonText: 'Add Brand'
    },
    {
      title: 'Add locations',
      description: 'Now, let\'s map out where your customers can enjoy your culinary creations. ' +
        'Add each restaurant location. Click \'Add Locations\' to proceed.',
      buttonText: 'Add Location'
    },
    {
      title: 'Connect menu',
      description: 'Link each location with your menu provider. ' +
        'This connection enables real-time menu updates. ',
      buttonText: 'Connect Menu'
    },
    {
      title: 'Create your first campaign',
      description: 'Launch a campaign with engaging games and diner rewards. ' +
        'Click \'Create Campaign\' to begin.',
      buttonText: 'Create Campaign'
    },
  ];

  const renderStep = (stepConfig: any, index: number, activeStepIndex: number) => {
    const isBeforeActiveStep = index < activeStepIndex;
    const isActiveStep = index === activeStepIndex;
    const isAfterActiveStep = index > activeStepIndex;

    let stepNumberContent;
    if (isBeforeActiveStep) {
      stepNumberContent = "✓"; // Checkmark for completed steps
    } else {
      stepNumberContent = index + 1; // Step number for current and future steps
    }

    const stepCircleStyle = isAfterActiveStep ? { backgroundColor: 'grey' } : {}; // Grey out the circle for steps after the active one

    return (
      <div className={classes.stepContainer} key={stepConfig.title}>
        <div className={classes.stepLeftContainer}>
          <div className={classes.stepNumber} style={stepCircleStyle}>
            {stepNumberContent}
          </div>
          {isActiveStep && <Divider orientation="vertical" flexItem className={classes.divider}/>}
        </div>
        <div className={classes.stepRightContainer}>
          <Typography variant="h6" className={classes.stepTitle}>
            {stepConfig.title}
          </Typography>
          {isActiveStep && <Typography className={classes.stepDescription}>
            {stepConfig.description}
          </Typography>}
          {isActiveStep && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={callbacks[index]}
            >
              {stepConfig.buttonText}
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {stepsConfig.map((stepConfig: any, index: number) => {
        return renderStep(stepConfig, index, activeStepIndex);
      })}
    </div>
  );
};


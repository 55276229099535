import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Typography, CircularProgress, Fab } from '@mui/material';
import { Add } from '@mui/icons-material';
import { toJS } from 'mobx';
import { SpacerV, YCenter, YTextField, Uploader, YButtonSecondary, YButtonPrimary, InfoPanel, YAvatar } from 'components/common';
import { useRootProvider } from 'providers/RootContext';
import CreateBrand from "./CreateBrand";
import drawerProvider from "../../providers/DrawerProvider";
import {BrandItem} from "./BrandItem";

type FormValues = {
  name: string;
  logo: File | undefined;
};

const Brands = observer(() => {
  const { businessProvider: bp, drawerProvider: dp } = useRootProvider();

  useEffect(() => {
    if (bp.brands === undefined) {
      bp.getBrands();
    }
  }, [bp]);

  const { handleSubmit, control, formState, watch, setValue, trigger } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { name: '', logo: undefined },
    shouldUnregister: false
  });

  const fields = watch();

  const createBrand = () => {
    dp.drawerOpen(<CreateBrand />, 'Create Brand');
  };

  return (
    <div>
      {bp.isFetching && bp.brands &&
        <YCenter minHeight="500px">
          <CircularProgress />
        </YCenter>
      }

      {!bp.isFetching && bp.brands && bp.brands.length === 0 &&
        <YCenter>
          <Typography variant="h6">No brands yet. Add one now.</Typography>
          <SpacerV size="30" />
          <YButtonSecondary onPress={createBrand}>Create Brand</YButtonSecondary>
        </YCenter>
      }

      {!bp.isFetching && <SpacerV size="30" />}
      {!bp.isFetching && (<Grid container spacing={2}>
        {bp.brands && bp.brands.length > 0 && bp.brands!.map((brand) => {
          return (
            <BrandItem key={brand.id} brand={brand}/>
          )
        })}
      </Grid>)}

      {!bp.isFetching && bp.brands && bp.brands.length > 0 &&
        <Fab color="primary"
             sx={{ position: 'absolute', bottom: 20, right: 20 }}
             onClick={createBrand}>
          <Add />
        </Fab>
      }
    </div>
  );
});

export default Brands;

import { makeAutoObservable, toJS} from 'mobx';
import RootProvider from './RootProvider';
import Api from 'api/api';


class UsersProvider {

  root: RootProvider;
  private userList = [];
  private paginationToken?: string;
  public isFetching = false;

  /* users getter */
  public get users() {
    return toJS(this.userList)
  }

  /*
   * getUsers will call service to get list of all team members
  */
  public getUsers = async () => {
    this.isFetching = true;
    try {
      const resp = await Api.user.listUsers();
      this.userList = resp.data.users;
      this.paginationToken = resp.data.paginationToken;
    } catch (e: any | Error) {
      this.root.errorProvider.checkApiError(e);
    }

    console.log("getUsers done, usersList[0]: ", toJS(this.userList[0]));
    this.isFetching = false;
  }

  constructor(root: RootProvider) {
    this.root = root;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}

export default UsersProvider;


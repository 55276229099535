import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.shape.borderRadius,
    },
    image: {
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      textAlign: 'center',
      textIndent: 10000
    }
}));

interface Props {
  name?: string;
  image: string;
  size: number;
}

export const Thumbnail: React.FC<Props> = props => {

  const classes = useStyles();
  const { name, image, size } = props;
  const alt = name ? name : 'yuzzle';

  return (
    <div style={{width: size, height: size}} className={classes.root}>
      <img alt={alt} src={image} className={classes.image} />
    </div>
  );
}

export default Thumbnail;

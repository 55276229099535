import { makeAutoObservable, toJS} from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import RootProvider from './RootProvider';
import Api from 'api/api';
import { TOAST_MESSAGE_TYPE } from 'providers/ToastProvider';
import AccountEntry from 'types/AccountEntry';
import Account from 'types/Account';
import { User, Brand } from 'types';
import Location from 'models/Location';


class BusinessProvider {

  root: RootProvider;
  private accountList = [];
  private userList = [];
  private accountsCount = 0;
  private accountsSortable = [];
  public isFetching = false;
  public accountEntry: AccountEntry = {};
  public accountExists = false;
  public business?: Account;

  private brandList?: Brand[];
  private teamList?: User[];
  private locationList?: Location[];
  private _locationMap?: Map<string, Location>;

  private tempBrandLogo?: File;

  public setFetching = (isFetching: boolean) => {
    this.isFetching = isFetching;
  }

  /* accounts getter */
  public get accounts() {
    return toJS(this.accountList)
  }

  /* users getter */
  public get users() {
    return toJS(this.userList)
  }

  public get team() {
    return toJS(this.teamList);
  }

  public get brands() {
    return toJS(this.brandList);
  }

  public get locations() {
    return toJS(this.locationList);
  }

  public get locationMap() {
    return this._locationMap;
  }

  private setBrands = (brands: Brand[]) => {
    if (this.brandList === undefined) {
      this.brandList = [];
    }

    this.brandList = brands;
  }

  private setTeam = (users: User[]) => {
    this.teamList = users;
  }

  private setBusiness = (account: Account)=> {
    this.business = account;
  }

  private setLocations = (locations: Location[]) => {
    this.locationList = locations;

    if (this.locationList.length > 0) {
      this._locationMap = new Map<string, Location>();
      for (const loc of this.locationList) {
        this._locationMap.set(loc.id, loc);
      }
    }
  }

  public enteredCompanyName = async (data: any) => {
    this.isFetching = true;
    try {
      this.accountExists = await Api.business.accountExists(data.ein);
      if (!this.accountExists) {
        this.accountEntry = data;
        this.root.navigate('/credentials');
      } else {
        this.root.toastProvider.showMessage(
          'Account already exists. Login to manage your account',
          TOAST_MESSAGE_TYPE.ERROR,
          6000
        );
      }
    } catch (e: any | Error) {
      console.log(e);
      //this.root.toastProvider.showMessage(e.message, TOAST_MESSAGE_TYPE.ERROR);
    }
  }

  public getAccountEntry = () => {
    return this.accountEntry;
  }

  public hasAccountEntry = () => {
    if (this.accountEntry.ein === '' || this.accountEntry.name === '') {
      return false;
    }

    return true;
  }

  public resetAccountEntry = () => {
    this.accountEntry = {};
  }

  /*
   * getAccounts will call service to get list of all accounts
   * this is used by admins only
  */
  public getAccounts = async () => {
    this.setFetching(true);
    try {
      const response = await Api.business.listAccounts();
      this.accountList = response.data;
      this.accountsCount = response.count;
      this.accountsSortable = response.sortable;
      this.setFetching(false);
    } catch (e: any | Error) {
      this.root.errorProvider.checkApiError(e);
      this.setFetching(false);
    }
  }

  /*
   * getUsers will call service to get list of all team members
  */
  public getMembers = async () => {
    this.setFetching(true);
    try {
      this.userList = await Api.business.listMembers();
      this.setFetching(false);
    } catch (e: any | Error) {
      this.root.errorProvider.checkApiError(e);
      this.setFetching(false);
    }
  }

  public createAccountForUser = async (userId: string) => {
    this.setFetching(true);
    try {
      const response = await Api.business.addAccount( userId, this.accountEntry);
      this.setBusiness(response.data)
      this.setFetching(false);
    } catch (e: any | Error) {
      this.root.errorProvider.checkApiError(e);
      this.setFetching(false);
    }
  }

  public setAccount = (account: Account) => {
    this.setBusiness(account);
  }

  public getAccount = async () => {
    this.setFetching(true);
    try {
      const response = await Api.business.accounts(this.root.userProvider.user!.id);
      this.setBusiness(response.data);
      this.setFetching(false);
    } catch (e: any | Error) {
      this.root.errorProvider.checkApiError(e);
      this.setFetching(false);
    }
  }

  public getBrands = async (ids: string[] = []) => {
    this.setFetching(true);
    try {
      let response;
      if (this.business) {
        response = await Api.business.brandsForAccount(this.business!.id);
      } else {
        if (ids.length > 0) {
          response = await Api.business.brands(ids.join(','));
        } else {
          response = await Api.business.brands();
        }
      }

      this.setBrands(response.data);
      this.setFetching(false);
    } catch (e: any | Error) {
      this.setBrands([]);
      this.root.errorProvider.checkApiError(e);
      this.setFetching(false);
    }
  }

  public createBrand = async (data: any) => {
    this.isFetching = true;

    this.root.drawerProvider.setFetching(true);
    try {
      if (!this.tempBrandLogo) {
        this.root.toastProvider.showMessage(
          'Brand Logo is required to create a brand',
          TOAST_MESSAGE_TYPE.ERROR,
          6000
        )

        return;
      }

      const upload = await Api.document.uploadImage(this.tempBrandLogo);
      data.logo = upload.data.small;

      await Api.business.createBrand(this.business!.id, data);
      this.getBrands();
      this.unsetBrandLogo();

      this.root.drawerProvider.setFetching(false);
      this.root.drawerProvider.close();

      this.root.tutorialProvider.inspect();
    } catch (e: any | Error) {
      this.root.drawerProvider.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }

    this.isFetching = false;
  }

  public deleteBrand = async (id: string) => {
    try {
      await Api.business.deleteBrand(id);
      await this.getBrands();
    } catch (e: any | Error){
      this.root.errorProvider.checkApiError(e);
    }
  }

  public deleteLocation = async (id: string) => {
    try {
      await Api.business.deleteLocation(id);
      await this.getLocations();
    } catch (e: any | Error){
      this.root.errorProvider.checkApiError(e);
    }
  }

  private unsetBrandLogo = () => {
    this.tempBrandLogo = undefined;
  }

  public setBrandLogo = (file: File) => {
    this.tempBrandLogo = file;
  }

  public getAccountTeam = async () => {
    this.setFetching(true);
    try {
      const response = await Api.business.teamForAccount(this.business!.id);

      const users: User[] = [];
      for (const u of response.data) {
        const res = await Api.user.getUser(u.userId);
        const user = res.data as User;
        users.push(user);
      };

      this.setTeam(users);
      this.setFetching(false);
    } catch (e: any | Error) {
      this.setTeam([]);
      this.root.errorProvider.checkApiError(e);
      this.setFetching(false);
    }
  }

  private getAccountLocations = async () => {
    this.setFetching(true);
    try {
      const response = await Api.business.locationsForAccount(this.business!.id);
      await this.getBrands();
      this.setLocations(Location.fromJsonList(response.data));
      this.setFetching(false);
    } catch (e: any | Error) {
      this.setLocations([]);
      this.root.errorProvider.checkApiError(e);
      this.setFetching(false);
    }
  }

  public getLocations = async () => {
    if (this.business?.id !== undefined) {
      return this.getAccountLocations();
    } else {
      this.setFetching(true);
      try {
        const response = await Api.business.listLocations();
        console.log('response:', response.data);
        this.setLocations(Location.fromJsonList(response.data));
        this.setFetching(false);
      } catch(e: any | Error){
        this.root.errorProvider.checkApiError(e);
        this.setFetching(false);
      }
    }
  }

  public saveAccount = async (data: any) => {
    this.root.drawerProvider.setFetching(true);
    try {
      const response = await Api.business.saveAccount(this.business!.id, data);
      this.setBusiness(response.data);
      this.root.drawerProvider.setFetching(false);
      this.root.drawerProvider.close();
    } catch (e: any | Error) {
      const err = e.response.data.error;

      this.setFetching(false);
      this.root.drawerProvider.setFetching(false);

      if (err.code === 422) {
        const fields = Object.keys(err.body).join(', ');
        this.root.toastProvider.showMessage(
            `Please check the following fields: ${fields}`,
            TOAST_MESSAGE_TYPE.ERROR,
            6000
        );
      } else {
        this.root.toastProvider.showMessage(
            err.message,
            TOAST_MESSAGE_TYPE.ERROR,
            6000
        );
      }
    }
  }

  public createLocation = async(data: any) => {
    this.isFetching = true;
    this.root.drawerProvider.setFetching(true);
    const { deliveryEnabled, deliveryFee, deliveryRadius, ...locationData } = data;

    try {
      const response = await Api.business.createLocation(this.business!.id, locationData);

      const location = new Location(response.data);
      const existingLocations = this.locationList ? this.locationList : [];
      this.setLocations([...existingLocations, location]);

      this.root.drawerProvider.setFetching(false);
      this.root.drawerProvider.close();

      this.root.tutorialProvider.inspect();

      if (deliveryEnabled) {
        await Api.business.saveLocationSettings(location.id, {deliveryEnabled, deliveryFee, deliveryRadius});
      }
    }
    catch(e: any | Error){
      this.root.drawerProvider.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }

    this.isFetching = false;
  }

  public updateLocation = async(data: any) => {
    this.isFetching = true;
    this.root.drawerProvider.setFetching(true);
    try {
      if(!data.brandId) {
        data.brandId = undefined;
      }
      
      const response = await Api.business.updateAccountLocation(data.id, data);

      const location = response.data as Location;
      const existingLocations = this.locationList ? this.locationList : [];
      const index = existingLocations?.findIndex((l: Location) => l.id === location.id);
      existingLocations[index] = location;
      this.setLocations([...existingLocations]);

      this.root.drawerProvider.setFetching(false);
      this.root.drawerProvider.close();
    }
    catch(e: any | Error){
      this.root.drawerProvider.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }

    this.isFetching = true;
  }

  constructor(root: RootProvider) {
    this.root = root;
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: 'BusinessProvider',
      properties: ['accountEntry', 'business'],
      storage: window.localStorage,
    }).then();
  }
}

export default BusinessProvider;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grow, Snackbar, Alert, SnackbarProps } from '@mui/material';
import { useRootProvider } from 'providers/RootContext';
import { TOAST_MESSAGE_TYPE } from 'providers/ToastProvider';

const MainToast = observer(() => {

  const { toastProvider } = useRootProvider();

  const severity = () => {
    const msgType = toastProvider.type;
    switch (msgType) {
      case TOAST_MESSAGE_TYPE.ERROR:
        return 'error';
      case TOAST_MESSAGE_TYPE.SUCCESS:
        return 'success';
      case TOAST_MESSAGE_TYPE.INFO:
        return 'info';
      default:
        return 'error';
    }
  }

  const props: SnackbarProps = {};
  if (toastProvider.duration > 0) {
    props.autoHideDuration = toastProvider.duration;
  }

  return (
    <Grow in={toastProvider.hasMessage()}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={toastProvider.open}
        onClose={toastProvider.clearMessage}
        {...props}
      >
        <Alert variant="filled" severity={severity()}>
          {toastProvider.message}
        </Alert>
      </Snackbar>
    </Grow>
  )
});

export default MainToast;

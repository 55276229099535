import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import Api from 'api/api';
import {YAvatar, YCard, YGrid} from 'components/common';
import {useRootProvider} from 'providers/RootContext';
import {
  Box,
  Button,
  Chip,
  Typography,
  CircularProgress, Fab
} from '@mui/material';
import {Add, Cancel, Edit, Publish} from '@mui/icons-material';
import {GridActionsCellItem, GridRenderCellParams} from '@mui/x-data-grid';
import {
  YCenter,
  SpacerV,
  YButtonSecondary
} from 'components/common';
import Campaign from 'models/Campaign';
import {
  CampaignEmptyForm,
} from 'data/formValues';
import {useNavigate} from "react-router-dom";
import CampaignWizzard from "./CampaignWizzard";

const Campaigns = observer(() => {
  const { campaignProvider: cp, userProvider } = useRootProvider();
  const navigate = useNavigate();

  // Edit campaing will set default values in store to selected campaign
  // set wizzard context to the campaing id
  // and open the drawer by setting current step to 0
  const handleEdit = (campaignData: any) => {
    const campaign = new Campaign(campaignData);
    cp.setCurrentStep(0);
    cp.setDefaults('campaign', campaign);
    cp.setEditingCampaign(campaign);
  }

  const handlePublish = async (campaign: Campaign) => {
    await Api.campaign.publish(campaign.id).then(() => {
      cp.getCampaigns();
    });
  }

  const handleUnPublish = async (campaign: Campaign) => {
    await Api.campaign.unPublish(campaign.id).then(() => {
      cp.getCampaigns();
    });
  }

  let columns = [
    {
      field: 'image', headerName: 'Image',
      renderCell: ((params: GridRenderCellParams<string>) => {
        return (<YAvatar noBorder image={params.row.image} name={params.row.name} size={40} />)
      })
    },
    { field: 'name', headerName: 'Name', flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <Button
            color="primary"
            sx={{textTransform: 'none'}}
            href={`/campaigns/${params.row.id}/details`}
        >{params.row.name}
        </Button>
      )
    },
    { field: 'brand', headerName: 'Brand', flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <Button color="primary" sx={{textTransform: 'none'}}>{cp.brands.get(params.row.brand)}</Button>)
    },
    { field: 'createdAt', headerName: 'Created On', type: 'dateTime', flex: 0.2,
      valueGetter: (params: GridRenderCellParams<string>) =>
        params.row.createdAt ? new Date(params.row.createdAt) : null
    },
    { field: 'redeemed', headerName: 'Redeems', type: 'number',
      valueGetter: (params: GridRenderCellParams) => {
        const campaignStats = cp.stats.get(params.row.id);
        return campaignStats ? campaignStats.redeemed : 0;
      }
    },
    { field: 'participated', headerName: 'Played', type: 'number',
      valueGetter: (params: GridRenderCellParams) => {
        const campaignStats = cp.stats.get(params.row.id);
        return campaignStats ? campaignStats.participated : 0;
      }
    },
    { field: 'status', headerName: 'Status', flex: 0.3,
      renderCell: ((params: GridRenderCellParams<string>) => {
        return (
          <Chip label={params.row.status.toUpperCase() }
          color={ params.row.status === 'published' ? 'success' : 'default' } />
        )
      })
    },
    {
      field: 'actions', type: 'actions',
      getActions: (params: GridRenderCellParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          disabled={params.row.status === 'published'}
          onClick={() => {handleEdit(params.row)}}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<Cancel />}
          label="Unpublish"
          disabled={params.row.status !== 'published'}
          onClick={() => {handleUnPublish(params.row)}}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<Publish />}
          label="Publish"
          disabled={params.row.status === 'published'}
          onClick={() => {handlePublish(params.row)}}
          showInMenu
        />,
      ],
    },
  ];

  // if user is admin, insert account column
  if (userProvider.isAdmin) {
    columns = [
      ...columns.slice(0, 2),
      { field: 'accountId', headerName: 'Account', renderCell: (params: GridRenderCellParams) => (
        <Button color="primary" sx={{textTransform: 'none'}}>{params.row.accountId}</Button>
      )},
      ...columns.slice(2)
    ]
  }

  // get list of campaigns from api
  useEffect(() => {
    cp.getCampaigns();
  }, [cp]);

  const initialOpen = () => {
    cp.setDefaults('campaign', CampaignEmptyForm);
    cp.resetContext();
    cp.setCurrentStep(0);
  }

  if (cp.editingCampaign){
    return (
      <CampaignWizzard />
    );
  }

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      {cp.fetching &&
        <YCenter>
          <CircularProgress />
        </YCenter>
      }

      {!cp.fetching && cp.campaigns && cp.campaigns.length === 0 &&
        <YCenter>
          <Typography variant="h6">No campaigns yet. Create one now.</Typography>
          <SpacerV size="30" />
          <YButtonSecondary onPress={() => cp.startNewCampaign()}>Create a Campaign</YButtonSecondary>
        </YCenter>
      }

      {!cp.fetching && cp.campaigns && cp.campaigns.length > 0 &&
      <YCard>
        <YGrid
          columns={columns}
          rows={cp.campaigns}
        />
      </YCard>
      }
      {!cp.fetching && (
        <Fab color="primary"
             sx={{ position: 'fixed', bottom: 20, right: 20 }}
             onClick={() => cp.startNewCampaign()}>
          <Add />
        </Fab>
      )}
    </Box>
  );
})

export default Campaigns;


import { AxiosInstance } from 'axios';
import { Api, Service, createApi } from './api';
import UsersMock from 'data/users';

interface UserService extends Service {
  refreshToken: Function;
}

class User implements UserService {
  public path: String = 'user/v1';
  public client?: AxiosInstance | undefined;

  constructor() {
    const port = process.env['REACT_APP_USER_API_PORT'];
    this.client = createApi(this.path, port);
  }

  /*
   * Retrieves list of all users / players
  */
  public listUsers = async (): Promise<any> => {
    if (this.client === undefined) {
      return Promise.resolve(UsersMock.listUsers());
    }

    const path = '/users';
    return await Api.get(this.client!, path);
  };

  public getUser = async (id: string): Promise<any> => {
    const path = `/users/${id}`;
    return await Api.get(this.client!, path);
  }

  public login = async (data: any): Promise<any> => {
    const path = '/login';

    return await Api.post(this.client!, path, data);
  };

  public refreshToken = async (userId: string, token: string) : Promise<any> => {
    console.log('refreshing token');
    const path = '/login/refresh';

    return await Api.post(this.client!, path, {
      userId,
      refreshToken: token
    });
  };

  public create = async (email: string, password: string) : Promise<any> => {
    const path = '/signup';

    return await Api.post(this.client!, path, {email, password});
  }

  public verify = async (email: string, code: string) : Promise<any> => {
    const path = '/signup/verify';

    return await Api.post(this.client!, path, {email, code});
  }

  public resendCode = async (email: string) : Promise<any> => {
    const path = '/signup/resend-code';

    return await Api.post(this.client!, path, {email});
  }

  public save = async (user: any) : Promise<any> => {
    const path = '/users/me';

    return await Api.patch(this.client!, path, user);
  }

  public restorePassword = async (email: string) : Promise<any> => {
    const path = '/password/restore';

    return await Api.post(this.client!, path, {email});
  }

  public updatePassword = async (email: string, code: string, password: string) : Promise<any> => {
    const path = '/password/update';

    return await Api.post(this.client!, path, {email, code, password});
  }
}

export default User;

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {Outlet, Route, Routes, Navigate} from "react-router-dom";

import MainToast from 'system/MainToast';
import MainDrawer from 'system/MainDrawer';

import AuthRoute from './AuthRoute';
import DashboardLayout from 'layout/dashboard/DashboardLayout';
import SignupLayout from 'layout/signup/SignupLayout';
import Dashboard from 'containers/dashboard/Dashboard';
import Accounts from 'containers/accounts/Accounts';
import Users from 'containers/users/Users';
import Reports from 'containers/reports/Reports';
import Campaigns from 'containers/campaigns/Campaigns';
import Orders from 'containers/orders/Orders';
import Signin from 'containers/signin/Signin';
import Signup from 'containers/signin/Signup';
import Verification from 'containers/signin/Verification';
import PersonalInfo from 'containers/signin/PersonalInfo';
import ForgotPassword from 'containers/signin/ForgotPassword';
import NewPassword from 'containers/signin/NewPassword';
import Company from 'containers/signin/Company';
import Settings from 'containers/business/Settings';
import General from 'containers/business/general/General';
import Brands from 'containers/business/Brands';
import Locations from 'containers/business/locations/Locations';
import UsersSettings from 'containers/business/Users';
import Billing from 'containers/business/Billing';
import MyAccount from 'containers/profile/MyAccount';
import NotFound from 'containers/errors/NotFound';
import { useRootProvider } from 'providers/RootContext';
import { CircularProgress } from '@mui/material';
import YCenter from 'components/common/YCenter';
import {CampaignDetails} from "../containers/campaigns/CampaignDetails";
import {Details as CDetails} from "../containers/campaigns/Details";
import {Activity} from "../containers/campaigns/Activity";
import {OrderDetails} from "../containers/orders/Details";
import {AccountDetails} from "../containers/accounts/AccountDetails";
import {LocationDetails} from "../containers/business/locations/LocationDetails";
import {Menu} from "../containers/business/locations/Menu";
import {Details as LDetails} from "../containers/business/locations/Details";
import CampaignWizzard from "../containers/campaigns/CampaignWizzard";


const Main = observer(() => {
  const  rootProvider  = useRootProvider();
  const { userProvider, toastProvider: tp } = rootProvider;

  useEffect(() => {
    window.addEventListener('offline', (e) => {
      tp.showMessage('You are Offline', tp.types.ERROR, 0);
    });
  }, [tp]);

  useEffect(() => {
    window.addEventListener('online', (e) => {
      tp.showMessage('You are back Online', tp.types.SUCCESS, 6000);
    });
  }, [tp]);

  if (!userProvider.init) {
    return (
      <YCenter>
        <CircularProgress size={70} />
      </YCenter>
    );
  }


  return (
    <div>
      <MainToast />
      <MainDrawer />

      <Routes>

        <Route path="/" element={<Navigate to="sign-in" replace />} />
        <Route element = {<AuthRoute />} >
          <Route element={<DashboardLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="accounts" element={<Outlet />}>
              <Route path="" element={<Accounts />} />
              <Route path=":id" element={<AccountDetails />}>
                <Route path="general" element={<General />} />
                <Route path="brands" element={<Brands />} />
                <Route path="locations" element={<Locations />} />
                <Route path="users" element={<UsersSettings />} />
                <Route path="billing" element={<Billing />} />
              </Route>
            </Route>
            <Route path="users" element={<Users />} />
            <Route path="reports" element={<Reports />} />
            <Route path="campaigns" element={<Outlet />}>
              <Route path="" element={<Campaigns />} />
                <Route path=":id" element={<CampaignDetails />}>
                  <Route path="details" element={<CDetails />} />
                  <Route path="activity" element={<Activity />} />
                </Route>
            </Route>
            <Route path="orders" element={<Outlet />}>
              <Route path="" element={<Orders />} />
              <Route path=":id" element={<OrderDetails />}>
                <Route path="details" element={<OrderDetails />} />
              </Route>
            </Route>
            <Route path="settings" element={<Settings />}>
              <Route path="general" element={<General />} />
              <Route path="brands" element={<Brands />} />
              <Route path="locations" element={<Locations />} />
              <Route path="users" element={<UsersSettings />} />
              <Route path="billing" element={<Billing />} />
            </Route>
            <Route path="locations/:id" element={<LocationDetails />}>
              <Route path="details" element={<LDetails />} />
              <Route path="menu" element={<Menu />} />
            </Route>

            {/*<Route path="settings" element={<Settings />}>
              <Route path="general" element={<General />} />
              <Route path="brands" element={<Brands />} />
              <Route path="locations" element={<Locations />}>
                <Route path=":locationId" element={<LocationDetails />}>
                  <Route path="details" element={<Menu />} />
                  <Route path="details" element={<Menu />} />
                </Route>
              </Route>
              <Route path="users" element={<UsersSettings />} />
              <Route path="billing" element={<Billing />} />
            </Route>*/}
            <Route path="me" element={<MyAccount />} />
          </Route>
        </Route>

        <Route element={<SignupLayout />}>
          <Route path="/sign-in" element={<Signin />} />
          <Route path="/sign-up" element={<Company />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/personal-info" element={<PersonalInfo />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/credentials" element={<Signup />} />
        </Route>
        <Route element={<NotFound />} />
      </Routes>

    </div>
  );
});

export default Main;


import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer'
    }
}));

interface Props {
  children: React.ReactNode;
  onAction?: () => void;
}

export const Action = (props: Props) => {

  const { children, onAction } = props;
  const classes = useStyles();

  return (
    <div onClick={onAction} className={classes.root}>
      {children}
    </div>
  );
}

export default Action;


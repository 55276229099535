import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootProvider } from 'providers/RootContext';
import { YDrawer } from 'components/common';

const MainDrawer = observer(() => {

  const { drawerProvider: dp } = useRootProvider();

  return (
    <YDrawer
      anchor="right"
      width={dp.width}
      open={dp.open}
      onClose={dp.close}
      title={dp.title}
      onAction={dp.action}
      actionDisabled={dp.actionDisabled}
      actionLabel={dp.actionLabel}
      loading={dp.fetching}
    >
        {dp.children }
    </YDrawer>
  )
});

export default MainDrawer;


import React from 'react';
import {observer} from 'mobx-react-lite';
import {useRootProvider} from 'providers/RootContext';
import {Tab, Tabs, Theme} from "@mui/material";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTabs-flexContainer': {
        backgroundColor: '#FFFFFF',
        borderTopRightRadius: 16,
        borderTopLeftRadius: 16,
      }
    }
  }));

export const LocationDetails = observer(() => {
  const { businessProvider: bp, drawerProvider: dp } = useRootProvider();
  let { id } = useParams();
  const classes = useStyles();

  const tabs = [
    'details',
    'menu',
  ];

  const capitalize = ([first, ...rest]: any) =>
    first.toUpperCase() + rest.join('');

  const renderTabs = () => {
    return tabs.map((tab) => (
      <Tab
        key={tab}
        label={capitalize(tab)}
        value={tab}
        component={Link}
        to={`/locations/${id}/${tab}`}
      />
    ))
  }

  const location = useLocation();
  const val = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  return (
    <div className={classes.root!}>
      <Tabs variant="fullWidth" value={val} style={{marginBottom: '2em'}}>
        { renderTabs() }
      </Tabs>
      <Outlet />
    </div>
  )
});

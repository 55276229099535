import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import YGrid from 'components/common/YGrid';
import { useRootProvider } from 'providers/RootContext';
import {
  Box, Button, Typography
} from '@mui/material';
import {YCard} from "../../components/common";

const Users = observer(() => {
  const columns = [
    { field: 'name', headerName: 'First Name', flex: 2,
    renderCell: (params: any) => {
      return (
        // Later, we don't need details page for now
        /*<Button
          color="primary"
          sx={{textTransform: 'none'}}
          href={`/users/${params.row.id}/details`}
        >{params.row.name} {params.row.familyName}
        </Button>*/
        <div>{params.row.name} {params.row.familyName}</div>
      );
    }},
    { field: 'email', headerName: 'Email', flex: 2},
    { field: 'role', headerName: 'Role', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
  ];

  const { usersProvider } = useRootProvider();

  useEffect(() => {
    usersProvider.getUsers();
  }, [usersProvider]);

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      <YCard>
        <YGrid
          columns={columns}
          rows={usersProvider.users}

        />
      </YCard>
    </Box>
  );
});

export default Users;





import React from 'react';
import {
  IconButton,
  Badge,
} from '@mui/material';
import {
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles: Function = (theme: Theme) => ({
  root: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
});

const Notifications: React.FC = () => {
  const classes = useStyles(useTheme());
  return (
    <IconButton
      sx={classes.root}
      size="large"
      aria-label="show 17 new notifications"
      color="inherit"
    >
      <Badge badgeContent={17} color="error">
        <NotificationsIcon />
      </Badge>
    </IconButton>
  );
}

export default Notifications;

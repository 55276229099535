const difficulties = [
  {
    id: '25x420',
    label: 'Newbie',
    pieces: 25,
    duration: 420,
    hint: '(25 pieces 7 min) 95% of players will complete this game',
  },
  {
    id: '36x360',
    label: 'Intermediate',
    pieces: 36,
    duration: 360,
    hint: '(36 pieces 6 min) 75% of players will complete this game',
  },
  {
    id: '64x360',
    label: 'Advanced',
    pieces: 64,
    duration: 360,
    hint: '(64 pieces 6 min) 50% of players will complete this game',
  },
  {
    id: '81x300',
    label: 'Advanced',
    pieces: 81,
    duration: 300,
    hint: '(81 pieces 5 min) 50% of players will complete this game',
  },
  {
    id: '144x480',
    label: 'Expert',
    pieces: 144,
    duration: 480,
    hint: '(144 pieces 8 min) 10% of players will complete this game',
  }
];


export default difficulties;

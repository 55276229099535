import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import {StandardTextFieldProps, Theme} from '@mui/material';
import { Controller } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { EMAIL_PATTERN } from 'utils/const';

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '0 none',
      overflow: 'hidden',
      borderRadius: 16,
      fontWeight: 600,
      backgroundColor: 'rgba(190, 194, 227, 0.15)',
      padding: '3px 0px 0px',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: 'rgba(190, 194, 227, 0.15)',
      },
      '&$focused': {
        backgroundColor: 'rgba(190, 194, 227, 0.15)',
      },
      '& .MuiInputAdornment-positionStart': {
        marginLeft: '10px'
      },
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
    },
    focused: {
      backgroundColor: 'rgba(190, 194, 227, 0.15)',
    },
  })
);

interface Props {
  onEnter?: (val: string) => void;
  variant?: 'outlined' | 'filled' | 'standard';
}

interface ValidationProps{
  control?: any;
  pattern?: RegExp;
  isEmail?: boolean;
  isRequired?: boolean;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
}

export const YTextField = React.forwardRef<any, any>(
  (props, ref) => {

  const classes = useStyles();

  const {
    isRequired,
    isEmail,
    control,
    pattern,
    minLength,
    maxLength,
    InputProps,
    min,
    max,
    onEnter,
    ...rest
  } = props;

  const getPattern = () => {
    let valRule;
    if (isEmail) {
      valRule = {
        value: EMAIL_PATTERN,
        message: 'Please enter correct email'
      }
    }

    if (pattern !== undefined) {
      valRule = {
        value: pattern,
        message: ''
      };
    }

    return valRule;
  }

  const rules = {
    required: {value: isRequired || false, message: 'Required field'},
    pattern: getPattern(),
    minLength: minLength ? {value: minLength, message: `Minumum length is ${minLength} characters`} : undefined,
    maxLength: maxLength ? {value: maxLength, message: `Maximum length is ${maxLength} characters`} : undefined,
    min: min? {value: min, message: `Minumum value is ${min}`} : undefined,
    max: max? {value: max, message: `Maximum value is ${max}`} : undefined,
  }

  const enterHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (onEnter && evt.key === 'Enter') {
      onEnter(evt.currentTarget.value);
    }
  }

  return (
    <Controller
      name={props.name!}
      control={control}
      rules={rules}
      render={({
        field: {onChange, onBlur, value, ...field},
        fieldState: {invalid, error},
        formState,
      }) => (
        <TextField
          {...field}
          onChange={onChange}
          onBlur={onBlur}
          value={value || ''}
          variant="filled"
          margin="normal"
          error={invalid}
          helperText={error ? error?.message : null}
          inputProps={{
            onKeyDown: enterHandler
          }}
          InputProps={{ classes, disableUnderline: true, ...InputProps } as Partial<OutlinedInputProps>}
          {...rest}
        />
    )}
    />
  );
});

export default YTextField;

import React, { useRef, useState, useLayoutEffect } from 'react';
import { Grid } from '@mui/material';


interface Props {
  minHeight?: string;
  children: React.ReactNode;
}

export const YCenter: React.FC<Props> = props => {

  const boxRef = useRef<HTMLDivElement>(null);
  const [ y, setY ] = useState(0);

  const getPosition = () => {
    if (boxRef && boxRef.current && boxRef.current.offsetTop) {
      setY(boxRef.current.offsetTop);
    }
  }

  useLayoutEffect(() => {
    getPosition();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', getPosition);
  }, [])

  let { minHeight } = props;
  if (minHeight === undefined) {
    minHeight = `calc(100vh - ${y + 100}px)`;
  }

  return (
    <div ref={boxRef}>
      <Grid container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{minHeight: minHeight, overflow: 'hidden', boxSizing: 'border-box'}}
      >
        <Grid item sx={{align: 'center', textAlign: 'center'}}>
            {props.children}
        </Grid>
      </Grid>
    </div>
  );
}

export default YCenter;

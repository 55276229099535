import React from 'react';
import { observer } from 'mobx-react-lite';
import {Link, Outlet, useLocation, useParams} from 'react-router-dom';
import {
  Tabs,
  Tab
} from '@mui/material';

export const AccountDetails = observer(() => {
  const {id} = useParams<{id: string}>();

  const tabs = [
    'general',
    'brands',
    'locations',
    'users',
    //'billing'
  ];

  const capitalize = ([first, ...rest]: any) =>
    first.toUpperCase() + rest.join('');

  const renderTabs = () => {
    return tabs.map((tab) => (
      <Tab
        key={tab}
        label={capitalize(tab)}
        value={tab}
        component={Link}
        to={`/accounts/${id}/${tab}`}
      />
    ))
  }

  const location = useLocation();
  const val = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  return (
    <div>
      <Tabs variant="fullWidth" value={val}>
        { renderTabs() }
      </Tabs>
      <Outlet />
    </div>
  )
});


import {AxiosInstance} from 'axios';
import {Api, createApi} from './api';

class Campaign {
  public path: String = 'campaign/v1';
  public client?: AxiosInstance | undefined;

  constructor() {
    const port = process.env['REACT_APP_CAMPAIGN_API_PORT'];
    this.client = createApi(this.path, port);
  }

  /* Returns list of of all campaigns for all accounts */
  public getAccountCampaigns = async (id: string): Promise<any> => {
    const path = `/accounts/${id}/campaigns`;
    return await Api.get(this.client!, path);
  };

  /* Returns list of of all campaigns for all accounts */
  public getCampaigns = async (): Promise<any> => {
    const path = `/campaigns`;
    return await Api.get(this.client!, path);
  };

  public createForAccount = async (accountId: string, data: any): Promise<any> => {
    const path = `/accounts/${accountId}/campaigns`;
    return await Api.post(this.client!, path, data);
  }

  public update = async (campaignId: string, data: any): Promise<any> => {
    const path = `/campaigns/${campaignId}`;
    return await Api.patch(this.client!, path, data);
  }

  public getCategories = async (): Promise<any> => {
    const path = '/categories';
    return await Api.get(this.client!, path);
  }

  public saveCategories = async (campaignId: string, categories: string[]): Promise<any> => {
    console.log('saveCategories', categories);
    const path = `/campaigns/${campaignId}/categories`;
    const ids = { categoryIds: categories };
    return await Api.post(this.client!, path, ids);
  }

  public saveCampaignLocations = async (campaignId: string, locations: object[]): Promise<any> => {
    const path = `/campaigns/${campaignId}/locations`;
    return await Api.post(this.client!, path, { locations });
  }

  public publish = async (campaignId: string): Promise<any> => {
    const path = `/campaigns/${campaignId}/publish`;
    return await Api.post(this.client!, path, {});
  }

  public unPublish = async (campaignId: string): Promise<any> => {
    const path = `/campaigns/${campaignId}/unpublish`;
    return await Api.post(this.client!, path, {});
  }
}

export default Campaign;


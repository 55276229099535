import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useRootProvider} from 'providers/RootContext';
import {Box, Button, Step, StepLabel, Stepper} from '@mui/material';

import CreateCampaign from './CreateCampaign';
import CampaignAssets from './CampaignAssets';
import ChooseCategories from './ChooseCategories';
import SetupGame from './SetupGame';
import SetupReward from './SetupReward';
import TargetLocations from './TargetLocations';
import FinePrint from './FinePrint';
import Success from './Success';
import YButtonPrimary from "../../components/common/YButtonPrimary";

const CampaignWizard = observer(() => {
  const { campaignProvider: cp, wizardProvider: wp } = useRootProvider(); // Assuming you are using RootProvider for other contexts

  const steps = [
    { label: 'Create Campaign', Component: CreateCampaign },
    { label: 'Campaign Assets', Component: CampaignAssets },
    { label: 'Choose Categories', Component: ChooseCategories },
    { label: 'Setup Game', Component: SetupGame },
    { label: 'Target Locations', Component: TargetLocations },
    { label: 'Setup Reward', Component: SetupReward },
    { label: 'The Fine Print', Component: FinePrint },
    /*{ label: 'Extras', Component: Extras },*/
    { label: 'Success', Component: Success }
  ];

  const renderStepContent = () => {
    const StepComponent = steps[wp.currentStep].Component;
    return <StepComponent />;
  };

  useEffect(() => {
    wp.currentStep = 0;
  }, []);

  return (
    <Box sx={{ pt: 2, pl: 4, pr: 4}}>
      <Stepper activeStep={wp.currentStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2, pr: 2, pl: 2, pt: 2, pb: 2 }} style={{width: '100%', display: "flex", justifyContent: "center"}}>
        <div style={{minWidth: 700, maxWidth: '100%'}}>
          {renderStepContent()}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button disabled={wp.currentStep === 0} onClick={wp.prevStep}>
              Back
            </Button>
            <YButtonPrimary
              variant="contained"
              color="primary"
              onPress={wp.currentStep === steps.length - 1 ? cp.stopEditingCampaign : wp.nextStep}
              disabled={wp.onNext === undefined}
              loading={cp.fetching || wp.loading}
              style={{marginBottom: '1em'}}
            >
              {wp.currentStep === steps.length - 1 ? 'Finish' : 'Next'}
            </YButtonPrimary>
          </Box>
        </div>
      </Box>
    </Box>
  );
});

export default CampaignWizard;

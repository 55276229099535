import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useRootProvider } from 'providers/RootContext';
import { useForm } from 'react-hook-form';
import {
  Typography
} from '@mui/material';
import SpacerV from 'components/common/SpacerV';
import YTextField from 'components/common/YTextField';
import YButtonPrimary from 'components/common/YButtonPrimary';
import YButtonLink from 'components/common/YButtonLink';

const useStyles: Function = makeStyles((theme: Theme) => createStyles({
  root: {
    textAlign: 'center'
  },
  title: {
    color: theme.palette.text.secondary,
  }
}));

interface Props {}

type FormData = {
  email: string;
  password: string;
  confirmPassword: string;
}

function Signup(props: Props) {
  const classes = useStyles();
  const { userProvider } = useRootProvider();

  const defaultValues = {
    email: '',
    password: '',
    confirmPassword: '',
  }

  const { handleSubmit, control } = useForm<FormData>({ defaultValues });

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Signup
      </Typography>
      <SpacerV size="small" />

      <Typography variant="body1" >
        Signup to start posting campaigns
      </Typography>
      <SpacerV />

      <YTextField
        fullWidth
        isRequired
        isEmail
        control={control}
        type="email"
        label="Email"
        name="email"
      />

      <YTextField
        fullWidth
        isRequired
        minLength={8}
        control={control}
        type="password"
        label="Password"
        name="password"
      />

      <YTextField
        fullWidth
        isRequired
        minLength={8}
        control={control}
        type="password"
        label="Confirm Password"
        name="confirmPassword"
      />

      <SpacerV size="small"/>

      <YButtonPrimary fullWidth onPress={handleSubmit(userProvider.createUser)} >
        Next
      </YButtonPrimary>
      <SpacerV size="small"/>

      <SpacerV size="large"/>

      <Typography variant="body1" >
        Already a member?
      </Typography>
      <YButtonLink to="/sign-in">Sign In</YButtonLink>
    </div>
  );
}

export default Signup;


import axios, { AxiosInstance } from 'axios';
import User from './user';
import Business from './business';
import Order from './order';
import Campaign from './campaign';
import Prize from './prize';
import Document from './document';
import Game from './game';
import Catalog from "./catalog";
import Payment from "./payment";

export interface Service {
  path: String;
  client?: AxiosInstance;
}

export const createApi = (path: String, port?: String): (AxiosInstance | undefined) => {
  const baseURL = process.env['REACT_APP_API_URL'];

  if (baseURL === '' || baseURL === undefined) {
    return undefined;
  }

  const url = `${process.env['REACT_APP_API_URL']}${port ? ':' : ''}${port ? port : ''}/${path}`;

  const api = axios.create({ baseURL: url });

  return api;
};

export class Api {
  public user = new User();
  public business = new Business();
  public order = new Order();
  public payment = new Payment();
  public campaign = new Campaign();
  public catalog = new Catalog();
  public prize = new Prize();
  public document = new Document();
  public game = new Game();

  static post = async (client: AxiosInstance, path: string, data: any): Promise<any> => {
    this.setAuth(client)
    try {
      Api.checkConnection();
      const res = await client!.post(path, data);
      return Promise.resolve(res.data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  static get = async (client: AxiosInstance, path: string): Promise<any> => {
    this.setAuth(client)
    try {
      Api.checkConnection();
      const res = await client!.get(path);
      return Promise.resolve(res.data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  static patch = async (client: AxiosInstance, path: string, data: any): Promise<any> => {
    this.setAuth(client)
    try {
      Api.checkConnection();
      const res = await client!.patch(path, data);
      return Promise.resolve(res.data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  static delete = async (client: AxiosInstance, path: string): Promise<any> => {
    this.setAuth(client)
    try {
      Api.checkConnection();
      const res = await client!.delete(path);
      return Promise.resolve(res.data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  static setAuth(instance: AxiosInstance) {
    const provider = localStorage.getItem('UserProvider');
    const credentials = JSON.parse(provider!)?.credentials;
    if (credentials?.token) {
      instance.defaults.headers.common['authorization'] = `Bearer ${credentials.token}`;
    } else {
      delete instance.defaults.headers.common['authorization'];
    }
  }

  static checkConnection() {
    if (window.navigator.onLine) {
      return true;
    } else {
      throw Error('No Internet Connection');
    }
  }
}

export default new Api();

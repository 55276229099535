import Game from 'models/Game';
import Reward from 'models/Reward';
import Campaign from 'models/Campaign';

class WizzardContext {
  public brandId: string;
  public campaign: Campaign;
  public campaignImage?: File | undefined;
  public campaignVideo?: File | undefined;

  public game: Game;
  public gameImage?: File;
  public isEditing: boolean;
  public reward: Reward;

  public constructor() {
    this.brandId = '';
    this.campaign = new Campaign({});
    this.game = new Game({});
    this.isEditing = false;
    this.reward = new Reward({});
  }

  public setCampaign(campaign: Campaign) {
    this.campaign = campaign;
  }

  // This function updates campaign in the context in a way
  // that it copies the categories from the original campaign
  // and associates them to the latest snaphot of the campaign
  // that doesn't include the categories
  public updateCampaign(campaigns: Campaign[]) {
    const original = campaigns.find((obj) => obj.id = this.campaign.id);
    if (original) {
      this.campaign.categories = original.categories;
    }
  }

  public setGame(game: Game) {
    this.game = game;
  }

  public setGameImage = (image: File) => {
    this.gameImage = image;
  }

  public setCampaignImage = (image: File | undefined) => {
    this.campaignImage = image;
  }

  public setCampaignVideo = (video: File | undefined) => {
    this.campaignVideo = video;
  }

  public setEditing(e: boolean) {
    this.isEditing = e;
  }

  public setReward(reward: Reward) {
    this.reward = reward;
  }

  public reset = () => {
    this.brandId = '';
    this.campaign = new Campaign({});
    this.game = new Game({});
    this.reward = new Reward({});
    this.isEditing = false;
  }

  public hasImage = () => {
    return this.gameImage !== undefined;
  }

  public hasCampaignImage = () => {
    return this.campaignImage !== undefined;
  }

  public hasCampaignVideo = () => {
    return this.campaignVideo !== undefined;
  }
}

export default WizzardContext;

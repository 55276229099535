import { createTheme } from '@mui/material/styles';
import { Color } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    primaryLight: Palette['primary'];
    primaryDark: Palette['primary'];
  }
  interface PaletteOptions {
    primaryLight: PaletteOptions['primary'];
    primaryDark: PaletteOptions['primary'];
  }
}

const purple: Color = {
  50: '#ebeafe',
  100: '#cbcafc',
  200: '#a8a8fa',
  300: '#8284f9',
  400: '#6666f6',
  500: '#6667F6',
  600: '#473de5',
  700: '#3f30d8',
  800: '#3722cd',
  900: '#2900bc',
  'A100': '',
  'A200': '',
  'A400': '',
  'A700': '',
}

export const theme = createTheme({
  palette: {
    primary: {
      main: purple[500],
      light: purple[400],
      dark: purple[600]
    },
    primaryLight: {
      main: purple[100],
      light: purple[50],
      dark: purple[200]
    },
    primaryDark: {
      main: purple[700],
      light: purple[600],
      dark: purple[800]
    },
    secondary: {
      main: '#FFC768'
    },
    text: {
      primary: '#6A6E8E',
      secondary: '#222222',
    },
    grey: {
      900: '#24262B'
    },
    background: {
      default: '#f9f9f9'
    }
  },
  typography: {
    fontFamily: ['Poppins', 'Roboto'].join(','),
    h3: {
      fontSize: 52,
      fontWeight: 700
    },
    h4: {
      fontSize: 21,
      fontWeight: 700
    }
  },
  shape: {
    borderRadius: 16
  },
  //components: {
    //MuiAppBar: {
      //styleOverrides: {
        //colorPrimary: {
          //color: '#6A6E8E',
          //backgroundColor: '#ffffff'
        //}
      //}
    //}
  //}

});

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import YGrid from 'components/common/YGrid';
import { useRootProvider } from 'providers/RootContext';
import {
  Box, Button
} from '@mui/material';
import {YCard} from "../../components/common";
import {GridRenderCellParams} from "@mui/x-data-grid";

const Accounts = observer(() => {
  const columns = [
    { field: 'name', headerName: 'Name', flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          color="primary"
          sx={{textTransform: 'none'}}
          href={`/accounts/${params.row.id}/general`}
        >{params.row.name}
        </Button>
    )},
    { field: 'ein', headerName: 'EIN' },
    { field: 'address', headerName: 'Address', flex: 0.4 },
    { field: 'city', headerName: 'City', flex: 0.2 },
    { field: 'state', headerName: 'State' },
    { field: 'phone', headerName: 'Phone', minWidth: 150 },
  ];

  const { businessProvider } = useRootProvider();

  useEffect(() => {
    businessProvider.getAccounts();
  }, [businessProvider]);

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      <YCard>
        <YGrid
          columns={columns}
          rows={businessProvider.accounts}
        />
      </YCard>
    </Box>
  );
})

export default Accounts;

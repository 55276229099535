import React, {useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {observer} from 'mobx-react-lite';
import {useRootProvider} from 'providers/RootContext';
import {
  Box,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  Typography,
  TextField,
  Skeleton
} from '@mui/material';
import {
  SpacerV,
  YButtonPrimary,
} from 'components/common';
import Location from 'models/Location';
import {toJS} from "mobx";


const TargetLocations = observer(() => {
  const {campaignProvider: cp, wizardProvider: wp} = useRootProvider();

  const [filteredLocations, setFilteredLocations] = React.useState<Location[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [selectedLocationIds, setSelectedLocationIds] = React.useState<Set<string>>(new Set());

  const form = useForm<{ locations: { [key: string]: boolean } }>({
    mode: 'onChange',
  });

  useEffect(() => {
    Promise.all([cp.getBrandLocations()])
      .then(async () => {
        await cp.getLocationCatalogs(cp.brandLocations);

        const selectedIds = new Set(cp.ctx.campaign.locations.map(cl => cl.id));

        const defaultValues = cp.locations.reduce(
          (prev, cur) => ({
            ...prev,
            [cur.id]: selectedIds.has(cur.id)
          }),
          {});

        form.reset({locations: defaultValues});
        setSelectedLocationIds(selectedIds);

        setFilteredLocations(cp.locations);
      });
  }, [cp, form]);

  useEffect(() => {
    console.log('target locations, selectedLocations', toJS(selectedLocationIds));
    if (hasSelections()) {
      wp.setOnNext(async () => {
        await form.handleSubmit(cp.saveLocations)();
      });
    } else {
      wp.setOnNext(undefined);
    }
  }, [wp, cp, selectedLocationIds, filteredLocations, search, form, form.formState?.isValid]);

  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(evt.target.value);
    setFilteredLocations(cp.locations.filter(loc =>
      loc.name.toLowerCase().includes(evt.target.value.toLowerCase()) ||
      loc.address.toLowerCase().includes(evt.target.value.toLowerCase())
    ));
  }

  const onSelectAll = (checked: boolean) => {
    cp.locations.forEach(location => {
      form.setValue(`locations.${location.id}`, checked)
    })
  };

  const hasSelections = () => selectedLocationIds.size > 0;

  const areAllSelected = cp.locations.length > 0 &&
    cp.locations.every(l => selectedLocationIds.has(l.id));

  const setSelection = (locationId: string, selected: boolean) => {
    const selectedCopy = new Set(selectedLocationIds);

    if (selected) {
      selectedCopy.add(locationId);
    } else {
      selectedCopy.delete(locationId);
    }

    setSelectedLocationIds(selectedCopy);
    form.setValue(`locations.${locationId}`, selected);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <div>
        {cp.fetching && (
          <div>
            <Skeleton variant="rectangular" sx={{borderRadius: '16px'}} height={40} width={300}/>
            <Skeleton variant="text" sx={{fontSize: '1rem'}}/>
            <SpacerV/>
            <Skeleton variant="rectangular" sx={{borderRadius: '16px'}} height={40} width={300}/>
            <Skeleton variant="text" sx={{fontSize: '1rem'}}/>
            <SpacerV/>
            <Skeleton variant="rectangular" sx={{borderRadius: '16px'}} height={40} width={300}/>
            <Skeleton variant="text" sx={{fontSize: '1rem'}}/>
            <SpacerV/>
            <Skeleton variant="rectangular" sx={{borderRadius: '16px'}} height={40} width={300}/>
            <Skeleton variant="text" sx={{fontSize: '1rem'}}/>
            <SpacerV/>
          </div>
        )
        }


        {!cp.fetching && (
          <div>
            {cp.locations.length === 0 && (
              <Typography variant="body1">
                This brand has no locations setup. Please add locations before proceeding. You can resume where you left
                off later.
              </Typography>
            )}

            {cp.locations.length > 0 && (
              <div>
                <Typography variant="caption">
                  Select all locations to which this campaign applies
                </Typography>

                <TextField
                  fullWidth
                  margin="normal"
                  label="Search"
                  name="locationSearch"
                  placeholder="Type to Search"
                  value={search}
                  onChange={handleSearchChange}
                />

                <List>
                  {cp.locations.length > 5 && (
                    <ListItemButton key="all" onClick={() => onSelectAll(!areAllSelected)}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={areAllSelected}
                          onChange={(e) => onSelectAll(e.target.checked)}
                          disableRipple
                          inputProps={{'aria-label': 'checkbox-list-label-all'}}
                        />
                      </ListItemIcon>
                      <ListItemText primary="All Locations" secondary={`${cp.locations.length} locations`}/>
                    </ListItemButton>
                  )}

                  {
                    filteredLocations.map(l => {
                      const disabled = !cp.locationCatalogs.get(l.id);
                      return (
                        <div key={l.id}>
                          <ListItemButton
                            disabled={disabled}
                            key={l.id}
                            onClick={() => {
                              if (disabled) return;
                              setSelection(l.id, !selectedLocationIds.has(l.id));
                            }}>
                            <ListItemIcon>
                              <Controller
                                key={l.id}
                                name={`locations.${l.id}`}
                                control={form.control}
                                render={({field}) => (
                                  <Checkbox
                                    edge="start"
                                    checked={field.value}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    disableRipple
                                    disabled={disabled}
                                  />
                                )}
                              />
                            </ListItemIcon>
                            <ListItemText primary={l.name} secondary={l.address}/>
                          </ListItemButton>
                          {disabled && (
                            <Typography variant="caption" color="error"> This location has no catalog. Please add a
                              catalog to this location before proceeding. </Typography>)}
                        </div>
                      );
                    })
                  }
                </List>
              </div>
            )}
          </div>
        )}
      </div>
    </Box>
  );
})

export default TargetLocations;


import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useForm} from 'react-hook-form';
import {useRootProvider} from 'providers/RootContext';
import {Box, Skeleton} from '@mui/material';
import {SpacerV, YAutocomplete,} from 'components/common';
import Category from 'models/Category';

const ChooseCategories = observer(() => {
  const { campaignProvider: cp, wizardProvider: wp } = useRootProvider();

  const form = useForm({mode: 'onChange'});

  useEffect(() => {
    cp.getAllCategories().then((res: any) => {
      for (const [key, value] of Object.entries(res)) {
        form.setValue(key, value);
      }
    });
  }, [cp, form]);

  useEffect(() => {
    console.log('form.formState?.isValid', form.formState?.isValid);
    if (form.formState?.isValid) {
      wp.setOnNext(async () => {
        await form.handleSubmit(cp.saveCategories)();
      });
    } else {
      wp.setOnNext(undefined);
    }
  }, [cp, form, form.formState?.isValid]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <div>
        {cp.fetching && (
            <div>
              <Skeleton variant="rectangular" height={40} width={300} />
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />

              <SpacerV />

              <Skeleton variant="rectangular" height={40} width={300} />
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />
            </div>
          )
        }

        {!cp.fetching && cp.categories && cp.categories.length > 0 &&
          cp.categories.map((catList: Category) => (
            <YAutocomplete
              multiple={true}
              key={catList.id}
              control={form.control}
              name={catList.name.toLowerCase()}
              options={catList.categories as any}
              label={catList.name}
              helperText={`Type to search ${catList.name}`}
            />
          ))
        }
      </div>
    </Box>
  );
})

export default ChooseCategories;


import { makeAutoObservable, computed, action } from 'mobx';
import { makePersistable, hydrateStore } from 'mobx-persist-store';
import { User } from 'types';
import { now } from 'mobx-utils';
import { AxiosError } from 'axios';
import Api from 'api/api';
import RootProvider from './RootProvider';
import { Credentials } from 'types/Credentials';
import Account from 'types/Account';
import { TOAST_MESSAGE_TYPE } from 'providers/ToastProvider';

const ONE_HOUR = 60 * 60 * 1000;


/** The 2FA security token for user identity verification */
export interface IdentityVerificationToken {
  code: string;
  expiresAt: number;
  isActive: boolean;
  isValid: boolean;
}


/**
 * The UserProvider contains data and logic regarding the current
 * logged-in user.
 */
export default class UserProvider {
  root: RootProvider;

  public init: boolean = false;

  public credentials?: Credentials;
  public nextRoute: string = '/dashboard';
  public isSigningUp: boolean = false;
  public requiresPersonalInfo: boolean = false;

  public account?: string;

  /**
   * The duration of the refresh window in ms. If the token is this much or less
   * to the expiration, it will be refreshed.
   */
  private static REFRESH_WINDOW: number = ONE_HOUR;
  /** The object that represents the current user */
  public user?: User;

  /** When the JWT token expires, in epoch ms */
  private exp?: number;

  /** The encoded JWT */
  private jwt?: string;

  /** Whether the user is currently logging in */
  public loggingIn: boolean = false;

  public email: string = '';
  public pass?: string;

  public forgotPasswordFlow: boolean = false;
  public code: string = '';

  /**
   * The security token, if present. Use the computed value securityToken
   * for accessing this.
   */
  private identityVerificationToken_?: IdentityVerificationToken;

  /**
   * Returns the security token if it exists and isn't expired,
   * otherwise returns undefined.
   */
  public get identityVerificationToken(): IdentityVerificationToken | undefined {
    if (!this.identityVerificationToken_) {
      return undefined;
    }
    if (
      !this.identityVerificationToken_.expiresAt ||
      now() > this.identityVerificationToken_.expiresAt
    ) {
      return undefined;
    }
    return this.identityVerificationToken_;
  }

  public setIdentityVerificationToken(t: IdentityVerificationToken) {
    this.identityVerificationToken_ = t;
  }

  @computed public get loggedIn(): boolean {
    return Boolean(this.user) && !this.tokenExpired;
  }

  /** Whether the token is expired. If token expiry time is undefined, returns false */
  @computed public get tokenExpired(): boolean {
    return Boolean(
      this.credentials &&
      this.credentials.expiresAt &&
      now() >= this.credentials.expiresAt
    );
  }

  /** Whether the token is close to expiring. If token expiry time is undefined, returns false */
  @computed public get tokenAlmostExpired(): boolean {
    return Boolean(
      this.credentials?.expiresAt &&
      this.credentials.expiresAt - now() < UserProvider.REFRESH_WINDOW &&
      !this.tokenExpired
    );
  }

  /**
   * Represents the current authenticated user. Accessing throws an error if the current
   * user is not authenticated, so be careful.
   */
  @computed public get authUser(): User {
    if (!this.user) {
      throw new Error('Accessing auth user when user is not logged in');
    }
    return this.user;
  }


  /** Whether the current user is an admin */
  @computed public get isAdmin(): boolean {
    return Boolean(this.user && this.user.isAdmin);
  }

  /** Returns the user's full name */
  public fullName(): string {
    let name = '';
    if (this.loggedIn){
      if (this.authUser.name) {
        name += this.authUser.name;
      }
      if (this.authUser.familyName) {
        name += ` ${this.authUser.familyName}`;
      }
    }

    return name;
  }

  /** Whether the user has a first name and last name */
  @computed public get hasName(): boolean {
    return Boolean(
      this.user &&
        typeof this.user.name === 'string' &&
        typeof this.user.familyName === 'string',
    );
  }

  /**
   * Logs the user out.
   */
  public logout() {
    this.exp = undefined;
    this.jwt = undefined;
    this.user = undefined;
    this.account = undefined;
    this.credentials = undefined;
    this.root.businessProvider.setAccount({} as Account);
    this.root.drawerProvider.reset();
    this.root.drawerProvider.close();
    this.root.navigate('/sign-in');
  }

  public redirectFromLogin() {
    this.root.navigate(this.nextRoute);
  }

  /**
   * Calls the API endpoint to refresh the token and updates the auth
   * data with the new jwt token, user, and expiration.
   */
  public refreshToken = async () => {
    try {
      const res = await Api.user.refreshToken(this.user!.id, this.credentials!.refreshToken);
      this.setAuthData(res.data);
    } catch (e: any) {
      this.logout();
    }
  };

  /**
   * Calls the login API endpoint and stores user's jwt token if user has access
   */
  public login = async (data: any) => {
    this.root.businessProvider.setAccount({} as Account);
    this.email = '';
    this.pass = '';

    this.loggingIn = true;
    this.email = data.email;

    try {
      const res = await Api.user.login({...data});
      await this.setAuthData(res.data);

      // if user is admin, let em in otherwise check if is owner. Players not allowed.
      if (this.user?.isAdmin) {
        this.root.navigate(this.nextRoute);
      } else {
        // get accounts for the user
        const response = await Api.business.accounts(this.user!.id);

        if (response.count > 0) {
          this.root.businessProvider.setAccount(response.data[0]);
          this.toNextRoute();

        } else if (this.root.businessProvider.hasAccountEntry()) {
          await this.root.businessProvider.createAccountForUser(this.user!.id);
          this.root.businessProvider.resetAccountEntry();
          this.toNextRoute();

        } else {
          this.logout();
          this.root.toastProvider.showMessage(
            'Access to dashboard is restricted to restaurant operators',
            TOAST_MESSAGE_TYPE.ERROR,
            6000
          );
        }
      }
    } catch (e: any | Error) {
      if (e instanceof AxiosError) {
        // check if user verified and if not send to verifcation page
        if (e?.response?.data?.error?.body?.code === 'UserNotConfirmedException') {
          this.nextRoute = '/dashboard';
          await this.resendCode();
          this.root.navigate('/verification');
        }

        this.root.errorProvider.checkApiError(e, undefined);
      }
    }
  }

  public createUser = async (data: any) => {
    if (data.password !== data.confirmPassword) {
      this.root.toastProvider.showMessage('Passwords do not match', TOAST_MESSAGE_TYPE.ERROR, 6000);
      return;
    }

    this.email = data.email;
    this.pass = data.password;
    this.isSigningUp = true;
    this.requiresPersonalInfo = true;

    try {
      await Api.user.create(data.email, data.password);
      this.root.navigate('/verification');
    } catch (e: any | Error) {
      console.log(e.response.data);
      this.root.toastProvider.showMessage(e.response.data.error.message, TOAST_MESSAGE_TYPE.ERROR, 6000);
    }
  }

  // This function will start reset password flow
  public forgotPassword = async (email: string) => {
    console.log('sending restorePassword, email: ', email);
    return  Api.user.restorePassword(email);
  }

  // This function will save new password
  public resetPassword = async (email: string, code: string, newPassword: string) => {
    console.log(`sending updatePassword, email: ${email}, code: ${code}, newPassword: ${newPassword}`);
    return  Api.user.updatePassword(email, code, newPassword);
  }

  public resendCode = async () => {
    try {
      await Api.user.resendCode(this.email);
    } catch (e: any | Error) {
      this.root.toastProvider.showMessage('Could not resend code', TOAST_MESSAGE_TYPE.ERROR, 6000);
    }
  }

  public confirmCode = async (email: string, code: string) => {
    try {
      console.log('confirming code: ', email, code);
      await Api.user.verify(email, code);

      this.userVerifiedEmail();
    } catch (e: any | Error) {
      //this.root.toastProvider.showMessage('Could not confirm code');
      this.root.toastProvider.showMessage(e.response.data.error.message, TOAST_MESSAGE_TYPE.ERROR, 6000);
    }
  }

  /*
   * What happens when user verifies email
   * We create account if it exists
   * we login user automatically if user is in signup process
   * we delete account entry
   * order of execution is important
  */
  private userVerifiedEmail = async () => {
    if (this.root.businessProvider.hasAccountEntry()) {

      // If user just created an account, automatically log him in
      if (this.isSigningUp) {
        const res = await Api.user.login({email: this.email, password: this.pass});
        this.setAuthData(res.data);
        this.pass = undefined;
      }

      await this.root.businessProvider.createAccountForUser(this.user!.id);
      this.root.businessProvider.resetAccountEntry();
    }

    // If user didnt fill personal info we ask for that
    // otherwise redirect to next intended route
    this.toNextRoute();
  }


  public savePersonalInfo = async (data: any) => {
    try {
      await Api.user.save(data);
      this.requiresPersonalInfo = false;
      this.root.navigate(this.nextRoute);
    } catch (e: any | Error) {
      this.root.toastProvider.showMessage('Could not save user data. Try visiting login page', TOAST_MESSAGE_TYPE.ERROR, 6000);
    }
  }

  public toNextRoute = () => {
    this.root.navigate(this.calculatedRoute);
  }

  @computed public get calculatedRoute(): string {
    if (this.requiresPersonalInfo) {
      return '/personal-info';
    } else {
      return this.nextRoute;
    }
  }

  private setAuthData(data: any) {
    this.exp = (data.expiresIn * 1000) + now();
    const { user, ...creds } = data;
    this.credentials = {...this.credentials, ...creds};
    this.credentials!.expiresAt = this.exp;
    this.user = user;
  }

  public setAccount(account: any) {
    this.account = account;
  }

  public async hydrate() {
    await hydrateStore(this);
  }

  constructor(root: RootProvider) {
    this.root = root;
    makeAutoObservable(this, {}, { autoBind: true });


    makePersistable(this, {
      name: 'UserProvider',
      properties: [
        'email',
        'pass',
        'user',
        'account',
        'credentials',
        'nextRoute',
        'requiresPersonalInfo',
        'isSigningUp'
      ],
      storage: window.localStorage,
    }).then(
			action((persistStore) => {
        if (persistStore.isHydrated) {
          this.init = true;
        }
      })
    );
  }
}

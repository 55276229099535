import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import Box from '@mui/material/Box';
import YTextField from './YTextField';

const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const loadScript = (src: string, position: HTMLHeadElement | null, id: string) => {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

const autocompleteService: { current: null | google.maps.places.AutocompleteService } = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export interface AddressData {
  street?: string;
  streetNumber?: string;
  city?: string;
  zip?: string;
  state?: string;
  country?: string;
  lat?: string;
  lng?: string;
}

interface Props {
  name: string;
  control?:any;
  isRequired?: boolean;
  onPlaceSelect: (data: AddressData) => any;
  initialValue?: string; // New prop
}

export const YGoogleAddressAutocomplete : React.FC<TextFieldProps & Props> = props => {
  const { name, isRequired, control, onPlaceSelect, initialValue, ...rest } = props;
  const [value, setValue] = useState<PlaceType | null>(initialValue ? { description: initialValue, structured_formatting: { main_text: initialValue, secondary_text: '', main_text_matched_substrings: [] } } : null);

  const [inputValue, setInputValue] = useState(initialValue || '');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const loaded = useRef(false);

  const mapsScriptSrc = `https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&libraries=places`;

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        encodeURI(mapsScriptSrc),
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const getPlacePredictions = React.useMemo(
    () =>
      _.throttle((request: { input: string}, callback: (results?: readonly PlaceType[]) => void) => {
        if(autocompleteService.current) {
          (autocompleteService.current as any).getPlacePredictions(request, callback);
        }
      }, 200, { leading: true }),
    [],
  );

  const getAddressComponentByType = (addressComponents: any, type: string) => addressComponents?.find((el: any) => el.types.includes(type));

  const extractAndSetLocationData = async (addressInput: any): Promise<void> => {
    if(!addressInput) {
      return;
    }
    const geocoder = new google.maps.Geocoder();
    let resp: AddressData = {};
    await geocoder.geocode(
      {
        address: addressInput,
      },
      (results: any, status: any) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const location = results[0]?.geometry?.location;

          const lat = location?.lat();
          const lng = location?.lng();

          const addressComponents = results[0]?.address_components;

          const street = getAddressComponentByType(addressComponents, 'route')?.long_name;
          const streetNumber = getAddressComponentByType(addressComponents, 'street_number')?.long_name;

          const state = getAddressComponentByType(addressComponents, 'administrative_area_level_1')?.short_name;
          const locality = getAddressComponentByType(addressComponents, 'locality')?.long_name;
          const zipTown = getAddressComponentByType(addressComponents, 'postal_town')?.long_name;
          const city = locality ? locality : zipTown;
          const zip = getAddressComponentByType(addressComponents, 'postal_code')?.long_name;
          const country = getAddressComponentByType(addressComponents, 'country')?.long_name;
          
          resp = {
            street,
            streetNumber,
            lat,
            lng,
            city,
            state,
            zip,
            country,
          };
        }
      },
    );
    onPlaceSelect(resp);
  };

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] as never[] : []);
      return undefined;
    }

    getPlacePredictions({ input: inputValue }, async (results?: readonly PlaceType[]) => {
      
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    
    return () => {
      active = false;
    };
  }, [value, inputValue, getPlacePredictions]);

  return (
    <Autocomplete
      id="google-map"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={ async (event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        extractAndSetLocationData(newValue?.description);
      }}
      onInputChange={ async (event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
          <YTextField 
            {...params}
            label={props.label} 
            fullWidth
            isRequired={isRequired}
            control={control}
            margin="normal"
            variant="filled"
            name={name}
            value={inputValue}
            {...rest}
            />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default YGoogleAddressAutocomplete;

import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {
  CircularProgress,
  Typography,
  Box,
  Fab,
  Button, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@mui/material';
import {useForm} from 'react-hook-form';
import {
  YCard,
  YCenter,
  SpacerV,
  YGrid,
  YButtonSecondary,
} from '../../../components/common';
import {useRootProvider} from '../../../providers/RootContext';
import CreateLocation from './CreateLocation';
import {Add} from '@mui/icons-material';
import {GridRenderCellParams} from "@mui/x-data-grid";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {toJS} from "mobx";

const Locations = observer(() => {
  const {businessProvider: bp, drawerProvider: dp} = useRootProvider();

  const ActionMenuCell = (params: any) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOpenDialog = () => {
      handleClose(); // Close the menu before opening the dialog
      setDialogOpen(true);
    };

    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    const handleDelete = () => {
      bp.deleteLocation(params.id);
      handleDialogClose();
    };

    return (
      <>
        <IconButton onClick={handleClick}>
          <MoreVertIcon/>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleOpenDialog}>Delete</MenuItem>
        </Menu>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this location?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} style={{color: 'black'}}>
              Cancel
            </Button>
            <Button onClick={handleDelete} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const defaultValues = {
    name: '',
    email: '',
    phone: '',
    brandId: '',
    address: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    latitude: '',
    longitude: '',
    deliveryEnabled: false,
    deliveryFee: '',
    deliveryRadius: ''
  };

  const {setValue, ...form} = useForm({defaultValues, mode: 'onChange'});

  const columns = [
    {
      field: 'name', headerName: 'Name', flex: 5,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          color="primary"
          sx={{textTransform: 'none'}}
          href={`/locations/${params.row.id}/details`}
        >{params.row.name}
        </Button>
      )
    },
    {field: 'email', headerName: 'Email', flex: 4},
    {field: 'phone', headerName: 'Phone', flex: 2},
    {field: 'address', headerName: 'Address Name', flex: 4},
    {field: 'city', headerName: 'City', flex: 3},
    {field: 'state', headerName: 'State', flex: 1},
    {field: 'zip', headerName: 'ZIP'},
    {
      field: 'brand', flex: 3,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          color="primary"
          sx={{textTransform: 'none'}}
          href={`/brands/${params.row.brandId}/details`}
        >{params.row.brandName}
        </Button>
      ), headerName: 'Brand'
    },
    {
      field: 'menu',
      headerName: '',
      flex: 1,
      renderCell: ActionMenuCell,
    },
  ];

  useEffect(() => {
    if (bp.locations === undefined) {
      bp.getLocations();
    }
  }, [bp]);

  const getDrawerContent = (location?: any) => {
    if (location) {
      form.reset({...location.settings, ...location.brand, ...location});
      Object.keys(location).forEach((key) => {
        if (key === 'brand') {
          setValue('brandId', location[key].id);
          setValue('brandId', location[key].id);
        } else if (key === 'address') {
          setValue('address', location[key]);
        } else if (key === 'settings') {
          setValue('deliveryEnabled', location[key].deliveryEnabled);
          setValue('deliveryFee', location[key].deliveryFee);
          setValue('deliveryRadius', location[key].deliveryRadius);
        } else {
          // @ts-ignore
          setValue(key, location[key]);
        }
      });

      return <CreateLocation
        form={form}
        setValue={setValue}
        onAction={bp.isFetching ? undefined : bp.updateLocation}
      />;
    } else {
      Object.keys(defaultValues).forEach((key) => {
        // @ts-ignore
        setValue(key, defaultValues[key]);
      });

      return <CreateLocation
        form={form}
        setValue={setValue}
        onAction={bp.isFetching ? undefined : bp.createLocation}
      />;
    }

  };

  const createLocation = () => {
    dp.drawerOpen(getDrawerContent(), 'Create a Location');
  };

  return (
    <div>
      {bp.isFetching &&
        <YCenter>
          <CircularProgress/>
        </YCenter>}

      {!bp.isFetching && bp.locations && bp.locations.length === 0 &&
        <YCenter>
          <Typography variant="h6">No locations yet. Add one now.</Typography>
          <SpacerV size="30"/>
          <YButtonSecondary onPress={createLocation}>Add a Location</YButtonSecondary>
        </YCenter>}

      {bp.locations && bp.locations.length > 0 &&
        <Box sx={{width: '100%', height: '100%'}}>
          <YCard>
            <YGrid
              columns={columns}
              rows={bp.locations as any}
            />
          </YCard>
        </Box>}


      {!bp.isFetching && bp.locations !== undefined && bp.locations.length > 0 &&
        <Fab color="primary"
             sx={{position: 'absolute', bottom: 20, right: 20}}
             onClick={createLocation}>
          <Add/>
        </Fab>
      }
    </div>
  );
});

export default Locations;

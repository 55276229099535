import { AxiosInstance } from 'axios';
import { Api, createApi } from './api';

class Catalog {
  public path: String = 'catalog/v1';
  public adminPath: String = 'catalog/admin/v1';
  public client?: AxiosInstance | undefined;
  public adminClient?: AxiosInstance | undefined;

  constructor() {
    const port = process.env['REACT_APP_CATALOG_API_PORT'];
    this.client = createApi(this.path, port);
    this.adminClient = createApi(this.adminPath, port);
  }

  public getItems = async (s: string, locationId: string): Promise<any> => {
    const path = `/items?s=${s}&locationId=${locationId}`;

    return await Api.get(this.adminClient!, path);
  };

  public getMappings = async (name: string, locationIds: Array<string>): Promise<any> => {
    const path = '/items/get-mappings';

    const body = {
      name: name,
      locationIds: locationIds,
    };

    return await Api.post(this.adminClient!, path, body);
  };

  public getLocationCatalogs = async (locationId: string): Promise<any> => {
    const path = '/catalogs?locationId=' + locationId;

    return await Api.get(this.client!, path);
  };

  public addMenuToAccount = async (accountId: string, menu: any): Promise<any> => {
    const path = `/accounts/${accountId}/catalogs`;

    return await Api.post(this.client!, path, menu);
  }
}

export default Catalog;
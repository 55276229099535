import React from 'react';
import {
  Grid,
  Theme,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/styles';

interface Props {
  label: string;
  name?: string;
  value?: string;
  children?: React.ReactNode;
  type?: string; // text | link | image | control (default: text)
  canEdit?: boolean;
  onAction?: Function; // if type=control this will take action on the item
}

const useStyles: Function = (theme: Theme) => ({
  label: {
    textAlign: 'right',
    color: theme.palette.text.primary
  },
  value: {
    color: theme.palette.text.secondary,
    fontWeight: 400
  }
});

export const InfoPanelItem = (props: Props) => {
  const sx = useStyles(useTheme());
  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={4} sx={{padding: 1}}>
        <Typography variant="body1" sx={sx.label}>
          {props.label.toUpperCase()}
        </Typography>
      </Grid>

      {!props.value && props.children &&
        <Grid item xs={8}>
          {props.children}
        </Grid>
      }

      {props.value &&
        <Grid item xs={8}>
          <Typography variant="body1" sx={sx.value}>
            {props.value}
          </Typography>
        </Grid>
      }
    </Grid>
  )

}

export default InfoPanelItem;

import React from 'react';

function Reports() {
  return (
    <h1>Reports</h1>
  );
}

export default Reports;


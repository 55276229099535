import { AxiosInstance } from 'axios';
import { Api, createApi } from './api';

class Order {
  public path: String = 'order/v1';
  public client?: AxiosInstance | undefined;

  constructor() {
    const port = process.env['REACT_APP_ORDER_API_PORT'];
    this.client = createApi(this.path, port);
  }

  /* Returns list of of orders for specific account owner */
  public getAccountOrders = async (id: string): Promise<any> => {
    const path = `/accounts/${id}/orders`;
    return await Api.get(this.client!, path);
  };

  /* Returns list of of all orders for all accounts */
  public getOrders = async (): Promise<any> => {
    const path = `/orders`;
    return await Api.get(this.client!, path);
  };

  public getOrder = async (id: string): Promise<any> => {
    const path = `/orders/${id}`;
    return await Api.get(this.client!, path);
  }

  public getOrderForAccount = async (accountId: string, orderId: string): Promise<any> => {
    const path = `/accounts/${accountId}/orders/${orderId}`;
    return await Api.get(this.client!, path);
  }

  public update = async (orderId: string, data: any): Promise<any> => {
    const path = `/orders/${orderId}`;
    return await Api.patch(this.client!, path, data);
  }
}

export default Order;


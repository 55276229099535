import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Avatar from '@mui/material/Avatar';

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 3,
      borderRadius: '50%',
      background: `linear-gradient(to bottom, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`
    },
    inner: {
      width: '100%',
      height: '100%',
      border: '4px #fff solid',
      background: '#fff',
      borderRadius: '50%',
    },
    innerNoBorder: {
      width: '100%',
      height: '100%',
      background: '#fff',
      borderRadius: '50%',
    },
    image: {
      padding: 1,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      textAlign: 'center',
      textIndent: 10000
    }
}));

interface Props {
  name?: string;
  image: string;
  size: number;
  noBorder?: boolean;
}

export const YAvatar: React.FC<Props> = props => {
  const classes = useStyles();
  const { name, image, size, noBorder } = props;
  const alt = name ? name : 'yuzzle';

  return (
    <div style={{width: size, height: size}} className={noBorder ? 'none' : classes.root}>
      <div className={noBorder? classes.innerNoBorder : classes.inner}>
        <Avatar alt={alt} src={image} className={classes.image} />
      </div>
    </div>
  );
}

export default YAvatar;

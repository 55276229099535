import React from 'react';
import { makeAutoObservable } from 'mobx';
import RootProvider from './RootProvider';

class DrawerProvider {
  root: RootProvider;

  private drawerTitle: string = 'Edit';
  private isFetching: boolean = false;
  private drawerWidth: number = 500;
  private isOpen = false;
  private content: React.ReactNode;
  private fn: () => void = () => {};
  private fnLabel: string = 'Save';
  private _values: any = {};
  private onClose: () => void = () => {};
  public actionEnabled: boolean = false;

  public get fetching() {
    return this.isFetching;
  }

  public get width() {
    return this.drawerWidth;
  }

  public get title() {
    return this.drawerTitle;
  }

  public get open() {
    return this.isOpen;
  }

  public get children() {
    return this.content;
  }

  public get action() {
    return this.fn;
  }

  public get actionDisabled() {
    return !this.actionEnabled;
  }

  public get actionLabel() {
    return this.fnLabel;
  }

  public get values() {
    return this._values;
  }

  public close = () => {
    this.onClose();
    this.setOpen(false);
  }

  public reset = () => {
    this.setFetching(false);
    this.setTitle('Edit');
    this.setChildren(null);
    this.setAction(() => {});
    this.setActionLabel('Save');
  }

  private setTitle = (title: string) => {
    this.drawerTitle = title;
  }

  private setOpen = (open: boolean) => {
    this.isOpen = open;
  }

  private setChildren = (content: React.ReactNode) => {
    this.content = content;
  }

  private setActionLabel = (label: string) => {
    this.fnLabel = label;
  }

  public setAction = (action: () => void) => {
    this.fn = action;
  }

  public setActionState = (state: boolean) => {
    this.actionEnabled = state;
  }

  public setFetching = (isFetching: boolean) => {
    this.isFetching = isFetching;
  }

  public setWidth = (width: number) => {
    this.drawerWidth = width;
  }

  public setValues = (values: any) => {
    this._values = values;
  }

  public setOnClose = (onClose: () => void) => {
    this.onClose = onClose;
  }

  public drawerOpen = (
    children: React.ReactNode,
    title: string,
  ) => {
    this.setFetching(false);
    this.setChildren(children);
    this.setTitle(title);
    this.setOpen(true);
  }

  public drawerUpdate = (
    children: React.ReactNode,
    title: string,
  ) => {
    this.setChildren(children);
    this.setTitle(title);
  }

  constructor(root: RootProvider) {
    this.root = root;
    makeAutoObservable(this, {}, { autoBind: true });
  }

}

export default DrawerProvider;


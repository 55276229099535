import { AxiosInstance } from 'axios';
import { Api, createApi } from './api';
import { PrizeRequest }from 'types';

class Prize {
  public path: String = 'prize/v1';
  public client?: AxiosInstance | undefined;

  constructor() {
    const port = process.env['REACT_APP_PRIZE_API_PORT'];
    this.client = createApi(this.path, port);
  }

  public createPrize = async (accountId: string, payload: PrizeRequest) : Promise<any> => {
    const path = `/accounts/${accountId}/prizes`;
    return await Api.post(this.client!, path, payload);
  };

  public updatePrize = async (prizeId: string, payload: PrizeRequest) : Promise<any> => {
    const path = `/prizes/${prizeId}`;
    return await Api.patch(this.client!, path, payload);
  };

  public getPrizesForCampaign = async (campaignId: string) : Promise<any> => {
    const path = `/campaigns/${campaignId}/prizes`;
    return await Api.get(this.client!, path);
  };

  public getPrizeStats = async (id: string) : Promise<any> => {
    const path = `/prizes/${id}/stats`;

    const res = await Api.get(this.client!, path);
    return Object.assign({id}, res.data);
  }
}

export default Prize;


import React from 'react';
import {
  Box,
  Typography,
  Drawer,
  Divider,
} from '@mui/material';
import {
  Close
} from '@mui/icons-material';
import {
  SpacerV,
} from 'components/common';


interface Props {
  anchor: any;
  open: boolean;
  title: string;
  onClose: () => void;
  onAction?: () => void;
  actionLabel?: string;
  width?: number;
  loading?: boolean;
  actionDisabled?: boolean;
  children: React.ReactNode;
}

export const YDrawer: React.FC<Props> = props => {

  let { width } = props;
  if (width === undefined) {
    width = 480;
  }

  return (
    <Drawer
      open={props.open}
      anchor={props.anchor}
      onClose={props.onClose}
      PaperProps = {{
        sx: {
          width: width,
          padding: 3
        }
      }}
    >
      <Box sx={{flexDirection: 'row', display: 'flex'}}>
        <Typography sx={{flexGrow: 1, verticalAlign:'bottom'}} variant="h4">{props.title}</Typography>
        <Close onClick={props.onClose} sx={{cursor: 'pointer'}} />
      </Box>
      <SpacerV size="30" />
      <Divider />
      <SpacerV size="30" />

      <Box sx={{flexGrow:1}}>
        {props.children}
      </Box>
    </Drawer>
  );
}

export default YDrawer;


import { makeAutoObservable, toJS} from 'mobx';
import RootProvider from './RootProvider';
import Api from 'api/api';

class OrderProvider {

  root: RootProvider;
  private orderList = [];
  private orderMap = new Map();
  private orderCount = 0;
  private orderSortable = [];
  public isFetching = false;

  /* orders getter */
  public get orders() {
    return toJS(this.orderList)
  }

  /*
   * getOrders will call service to get list of all orders for specific account
  */
  public getOrders = async () => {
    this.isFetching = true;
    const business = this.root.businessProvider.business;

    try {
      let response;
      if (business?.id) {
        response = await Api.order.getAccountOrders(business.id);
      } else {
        response = await Api.order.getOrders();
      }
      this.orderList = response.data;

      this.orderList?.forEach((order: any) => {
        this.orderMap.set(order.id, order);
      });

      this.orderCount = response.count;
      this.orderSortable = response.sortable;
    } catch (e: any | Error) {
      this.root.errorProvider.checkApiError(e);
    }

    this.isFetching = false;
  }

  public getOrder = async (orderId: any)=> {
    this.isFetching = true;
    const business = this.root.businessProvider.business;

    let order = {};

    try {
      if (business?.id) {
        const resp = await Api.order.getOrderForAccount(business!.id!, orderId);
        order = resp.data;
      } else {
        const resp = await Api.order.getOrder(orderId);
        order = resp.data;
      }
    } catch (e: any | Error) {
      this.root.errorProvider.checkApiError(e);
    }

    this.isFetching = false;

    return order;
  }

  public refundPayment = async (orderId: string) => {
    this.isFetching = true;

    let order = this.orderMap.get(orderId);
    let reference = order.reference;

    try {
      await Api.payment.refund(reference);
      // Refresh orders list after successful refund
      await this.getOrders();
    } catch (e: any | Error) {
      this.root.errorProvider.checkApiError(e);
      throw e;
    } finally {
      this.isFetching = false;
    }
  }

  constructor(root: RootProvider) {
    this.root = root;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}

export default OrderProvider;

import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme, alpha, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import { TextFieldProps, OutlinedTextFieldProps } from '@mui/material/TextField';
import { OutlinedInputProps } from '@mui/material';
import { MuiChipsInput } from 'mui-chips-input';

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '0 none',
      overflow: 'hidden',
      borderRadius: 16,
      fontWeight: 600,
      backgroundColor: 'rgba(190, 194, 227, 0.15)',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: 'rgba(190, 194, 227, 0.15)',
      },
      '&$focused': {
        backgroundColor: 'rgba(190, 194, 227, 0.15)',
      },
    },
    formControl: {
      padding: '30px 10px 10px !important'
    },
    focused: {
      backgroundColor: 'rgba(190, 194, 227, 0.15)',
    },
  })
);

const useChipStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      background: alpha(theme.palette.primary.main, 0.2),
      color: theme.palette.text.secondary
    },
  })
);


interface Props{
  control?: any;
  isRequired?: boolean;
  onModify: (val: string[]) => void;
}

export const YTagsField: React.FC<TextFieldProps & OutlinedTextFieldProps & Props> = props => {

  const theme = useTheme();
  const classes = useStyles();
  const chipStyles = useChipStyles();

  const { control, name, onModify, onChange, variant, isRequired,  ...rest } = props;

  const rules = {
    required: {value: isRequired || false, message: 'Required field'},
  }

  return (
    <Controller
      control={props.control}
      name={props.name!}
      rules={rules}
      render={({ field, fieldState }) => (
        <MuiChipsInput
          variant="filled"
          {...rest}
          helperText={fieldState.error ? fieldState.error?.message : props.helperText}
          size="medium"
          classes={chipStyles}
          label="Tags"
          margin="normal"
          error={fieldState.invalid}
          InputProps={{ classes, disableUnderline: true } as Partial<OutlinedInputProps>}
          {...field}
          renderChip={(Component, props) => {
            return <Component
                {...props}
                sx={{
                  background: alpha(theme.palette.primary.main, 0.2),
                  color: theme.palette.text.secondary,
                    '& .MuiChip-deleteIcon': {
                      color: alpha(theme.palette.primary.main, 0.7),
                        '&:hover': {
                          color: theme.palette.primary.main
                        }
                    }
                }}
              />
          }}
        />
      )}
    />
  );
}

export default YTagsField;


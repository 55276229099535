import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink, LinkProps as RouterLinkProps  } from 'react-router-dom';
import { DistributiveOmit as Omit } from '@mui/types';
import {useRootProvider} from "../providers/RootContext";

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: theme.palette.common.white,
      fontWeight: 500,
    }
}));

interface Props {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

function ListItemLink(props: Props) {
  const { campaignProvider: cp } = useRootProvider();

  const classes = useStyles();
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((linkProps, ref) => (
        <RouterLink ref={ref} to={to} {...linkProps} onClick={() => {
          if (to.includes('campaigns')) {
            cp.stopEditingCampaign();
          }
        }}/>
      )),
    [to],
  );

  return (
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} classes={{primary: classes.text}} />
      </ListItem>
  );
}

export default ListItemLink;

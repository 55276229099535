import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Card from '@mui/material/Card';
import clsx from "clsx";

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //boxShadow: '0px 0px 16px 0px rgba(150, 150, 150, 0.15)',
      borderRadius: 16,
      padding: 30
    }
}));

interface Props {
  children?: React.ReactNode;
  height?: string;
  style?: React.CSSProperties;
  className?: any;
}

export const YCard: React.FC<Props> = props => {

  const classes = useStyles();
  const {children, height, style, className} = props;
  let styles = style || {};
  if (height) {
    styles = {height};
  }

  return (
      <Card elevation={0} style={styles} className={clsx(classes.root, className)}>
        {children}
      </Card>
  );
}
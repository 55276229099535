import React from 'react';
import {observer} from 'mobx-react-lite';
import {InfoPanel, YCard, YGrid} from 'components/common';
import {useRootProvider} from 'providers/RootContext';
import {createStyles, makeStyles} from "@mui/styles";
import {Autocomplete, Divider, Theme} from "@mui/material";
import TextField from "@mui/material/TextField";

const useStyles: Function = makeStyles((theme: Theme) =>
    createStyles({
        activityCard: {
            flex: 1,
            marginRight: 24,
        },
    }));

export const Activity = observer(() => {
    const { campaignProvider: cp, userProvider } = useRootProvider();
    const classes = useStyles();

    return (
        <div style={{width: "100%"}}>
            <div style={{display: "flex", marginBottom: 24}}>
                <div className={classes.activityCard}>
                     <InfoPanel title={"Activity"}>
                        <div>587</div>
                    </InfoPanel>
                </div>
                <div className={classes.activityCard}>
                    <InfoPanel title={"Games Played"}>
                        <div>587</div>
                    </InfoPanel>
                </div>
                <div className={classes.activityCard}>
                    <InfoPanel title={"Prizes Won"}>
                        <div>587</div>
                    </InfoPanel>
                </div>
                <div className={classes.activityCard}>
                    <InfoPanel title={"Orders Created"}>
                        <div>587</div>
                    </InfoPanel>
                </div>
                <div style={{flex: 1}}>
                    <InfoPanel title={"Screen Time"}>
                        <div>587</div>
                    </InfoPanel>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end", marginBottom: 24}}>
                <div style={{width: 360}}>
                    <TextField placeholder={"Type to search by prize code or user name"} fullWidth/>
                </div>
            </div>
            <div>
                <YGrid rows={[]} columns={[]}/>
            </div>
        </div>
    );
});


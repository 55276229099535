import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useRootProvider } from 'providers/RootContext';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Typography,
  Grid
} from '@mui/material';
import SpacerV from 'components/common/SpacerV';
import YTextField from 'components/common/YTextField';
import YButtonPrimary from 'components/common/YButtonPrimary';
import YButtonLink from 'components/common/YButtonLink';

const useStyles: Function = makeStyles((theme: Theme) => createStyles({
  root: {
    textAlign: 'center'
  },
  title: {
    color: theme.palette.text.secondary,
  }
}));

const Verification = observer(() =>  {
  const classes = useStyles();
  const { userProvider } = useRootProvider();

  const navigate = useNavigate();
  useEffect(() => {
    if (userProvider.email === '') {
      navigate('/sign-in');
    }

    if (userProvider.user?.verified) {
      userProvider.toNextRoute();
    }
  }, [userProvider, userProvider.email, userProvider.user, userProvider.user?.verified, navigate])

  const defaultValues = {
    code: '',
  }

  const {control, getValues } = useForm({ defaultValues });

  const handleVerification = async () => {
    try {
      const { code } = getValues();
      if (userProvider.forgotPasswordFlow){
        userProvider.forgotPasswordFlow = false;
        userProvider.code = code;
        console.log('setting code', userProvider.code);
        navigate('/new-password');
      } else{
        await userProvider!.confirmCode(userProvider.email, code);
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (!userProvider.email) {
    navigate('/sign-in');
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Confirm Email
      </Typography>
      <SpacerV size="small" />

      <Typography variant="body1" >
        We've sent a verification code to <b>{userProvider.email}</b>.
      </Typography>
      <Typography variant="body1" >
        Enter the code below to confirm your email.
      </Typography>
      <SpacerV />

      <YTextField
        fullWidth
        label="Enter Code"
        control={control}
        isRequired
        maxLength={6}
        name="code"
      />

      <SpacerV size="small"/>

      <YButtonPrimary fullWidth onPress={handleVerification} >
        Confirm
      </YButtonPrimary>
      <SpacerV size="large"/>

      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <YButtonLink to="/sign-up">Change Email</YButtonLink>
        </Grid>
        <Grid item>
          <YButtonLink onPress={userProvider.resendCode}>Resend Code</YButtonLink>
        </Grid>
      </Grid>
    </div>
  );
});

export default Verification;

import React from 'react';
import {createStyles, makeStyles} from '@mui/styles';
import {observer} from 'mobx-react-lite';
import {useRootProvider} from 'providers/RootContext';
import {Drawer, List, Theme} from '@mui/material';
import {
  BarChart,
  DashboardRounded as Dashboard,
  GroupRounded as Group,
  SettingsRounded as Settings,
  TocRounded as Toc,
  WorkRounded as Work
} from '@mui/icons-material';
import ListItemLink from 'components/ListItemLink';
import {ReactComponent as Logo} from 'images/white_logo.svg';

const drawerWidth = 300;

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      background: theme.palette.primary.main,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    icon: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1.5)
    },
    logo: {
      padding: 30,
      textAlign: 'center',
      align: 'center'
    },
    logoImage: {
        fill: '#000000'
    },
    divider: {
      background: theme.palette.grey[800]
    },
  }),
);

function Ads(props: {}) {
  return null;
}

const Sidebar = observer(() => {
  const classes = useStyles();
  const { userProvider } = useRootProvider();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.logo}>
        <Logo height={64} width={150} fill="#ffffff"  />
      </div>

      <List sx={{ marginTop: 2 }}>
        <ListItemLink to="dashboard" primary="Dashboard" icon={<Dashboard className={classes.icon} />} />

        {userProvider.isAdmin && (
          <ListItemLink to="accounts" primary="Accounts" icon={<Work className={classes.icon} />} />
        )}

        {userProvider.isAdmin && (
          <ListItemLink to="users" primary="Users" icon={<Group className={classes.icon} />} />
        )}

        <ListItemLink to="campaigns" primary="Campaigns" icon={<BarChart className={classes.icon} />} />
        <ListItemLink to="orders" primary="Orders" icon={<Toc className={classes.icon} />} />

        {!userProvider.isAdmin && (
          <ListItemLink to="settings/general" primary="Settings" icon={<Settings className={classes.icon} />} />
        )}
      </List>
    </Drawer>
  );
  });

export default Sidebar;

import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import {
  Button,
  ButtonProps
} from '@mui/material';

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      fontWeight: 700,
      background: theme.palette.common.white,
      borderRadius: 16,
      padding: '10px 30px',
      boxShadow: '0px 0px 16px 0px rgba(150, 150, 150, 0.15)',
      '&:hover': {
        background: theme.palette.common.white,
      }
    }
}));


interface Props {
  onPress: () => void;
}

export const YButtonSecondary: React.FC<Props & ButtonProps> = props => {

  const classes = useStyles();
  const {  children, onPress, ...rest} = props;

  return (
    <Button
      className={classes.root}
      size="large"
      variant="contained"
      //color="primary"
      onClick={onPress}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default (YButtonSecondary);


import React, {useEffect} from 'react';
import {makeStyles, createStyles} from '@mui/styles';
import {Theme, alpha, useTheme} from '@mui/material';
import {Controller} from 'react-hook-form';
import {TextFieldProps} from '@mui/material/TextField';
import {Autocomplete, TextField} from '@mui/material';
import {OutlinedInputProps} from '@mui/material/OutlinedInput';
import {AutocompleteOption} from 'types';
import {toJS} from "mobx";

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
      root: {
        border: '0 none',
        borderRadius: 16,
        fontWeight: 600,
        backgroundColor: 'rgba(190, 194, 227, 0.15)',
        padding: '3px 0px 0px',
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: 'rgba(190, 194, 227, 0.15)',
        },
        '&$focused': {
          backgroundColor: 'rgba(190, 194, 227, 0.15)',
        },
      },
      focused: {
        backgroundColor: 'rgba(190, 194, 227, 0.15)',
      }
    }
  )
);

interface Props {
  control?: any;
  isRequired?: boolean;
  options: AutocompleteOption[];
  name: string;
  inputValue?: string;
  onInputChange?: (event: React.ChangeEvent<{}>, value: string) => void;
  changeCallback?: (e: any, data: any) => void;
  multiple?: boolean;
  maxValues?: number;
}

export const YAutocomplete: React.FC<TextFieldProps & Props> = props => {

  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    console.log('YAutocomplete input value:', props.inputValue);
  }, [props.inputValue]);

  const {
    control,
    name,
    options,
    isRequired,
    variant,
    inputValue,
    onInputChange,
    changeCallback,
    maxValues,
    ...rest
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, value}, fieldState}) => (
        <Autocomplete
          multiple={props.multiple}
          id="tags"
          onChange={(e: any, data: any) => {
            if (maxValues && data.length > maxValues) {
              data = data.slice(-maxValues);
              value = data;
            }

            onChange(data);
            if (changeCallback) {
              changeCallback(e, data);
            }
          }}
          value={value ?? undefined}
          filterSelectedOptions
          options={options}
          getOptionLabel={(option) => option?.name ?? ''}
          inputValue={inputValue}
          onInputChange={onInputChange}
          isOptionEqualToValue={(option, value) => option?.name === value?.name}
          renderInput={({InputProps, ...params}) => (
            <TextField
              InputProps={{classes, disableUnderline: true, ...InputProps} as Partial<OutlinedInputProps>}
              {...params}
              label={props.label}
              fullWidth
              margin="normal"
              variant="filled"
              name={name}
              {...rest}
            />
          )}
          ChipProps={{
            sx: {
              background: alpha(theme.palette.primary.main, 0.2),
              color: theme.palette.text.secondary,
              '& .MuiChip-deleteIcon': {
                color: alpha(theme.palette.primary.main, 0.7),
                '&:hover': {
                  color: theme.palette.primary.main
                }
              }
            }
          }}
        />
      )}
    />
  );
}

export default YAutocomplete;


import { AxiosInstance } from 'axios';
import { Api, createApi } from './api';

class Payment {
  public path: String = 'payment/v1';
  public client?: AxiosInstance | undefined;

  constructor() {
    const port = process.env['REACT_APP_PAYMENT_API_PORT'];
    this.client = createApi(this.path, port);
  }

  public refund = async (reference: string): Promise<any> => {
    const path = `/payments/by-reference/${reference}/refund`;
    return await Api.post(this.client!, path, {});
  };
}

export default Payment; 
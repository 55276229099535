import React from 'react';
//import { useRootProvider } from 'providers/RootContext';

function Billing() {

  return (
      <h1>Billing</h1>
  );
}

export default Billing;


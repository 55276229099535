import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import {
  Button,
  ButtonProps,
  CircularProgress
} from '@mui/material';

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      fontWeight: 700,
      borderRadius: 16,
      padding: '30px 80px',
      boxShadow: '0px 0px 16px 0px rgba(150, 150, 150, 0.15)',
      '&:hover': {
        background: theme.palette.common.white,
      }
    }
}));


interface Props {
  onPress: () => void;
  loading?: boolean;
  boxColor?: string;
  textColor?: string;
}

export const YButtonBox: React.FC<Props & ButtonProps> = props => {

  const classes = useStyles();
  const {  children, onPress, loading, ...rest} = props;

  return (
    <Button
      className={classes.root}
      variant="contained"
      size="large"
      onClick={onPress}
      endIcon={loading ? <CircularProgress size={24} sx={{color: '#ffffff'}}/> : null}
      style={{ color: props.textColor, background: props.boxColor }}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default (YButtonBox);


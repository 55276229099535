import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
//import { useRootProvider } from 'providers/RootContext';
import {
  Box,
  Typography
} from '@mui/material';
import {YCard} from "../../components/common";

const MyAccount = observer(() => {


  useEffect(() => {
    console.log('running get my stuff');
  });

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      <YCard>
        <Typography>Custom Content Here</Typography>
      </YCard>
    </Box>
  );
})

export default MyAccount;


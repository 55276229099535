import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useRootProvider } from 'providers/RootContext';
import { useNavigate } from 'react-router-dom';
import {
  Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import SpacerV from 'components/common/SpacerV';
import YTextField from 'components/common/YTextField';
import YButtonPrimary from 'components/common/YButtonPrimary';
import YButtonLink from 'components/common/YButtonLink';

const useStyles: Function = makeStyles((theme: Theme) => createStyles({
  root: {
    textAlign: 'center'
  },
  title: {
    color: theme.palette.text.secondary,
  }
}));


const Signin = observer(() => {
  const classes = useStyles();
  const { userProvider } = useRootProvider();

  const navigate = useNavigate();

  useEffect(() => {
    if (userProvider.loggedIn) {
      navigate(userProvider.calculatedRoute);
    }

    if (userProvider.tokenExpired) {
      userProvider.refreshToken();
    }

  }, [userProvider, navigate])


  const defaultValues = {
    email: '',
    password: ''
  }

  const { handleSubmit, control } = useForm({ defaultValues });

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Sign In
      </Typography>
      <SpacerV size="small" />

      <Typography variant="body1" >
        Sign in to manage campaigns, see deposits, <br/>
        run reports and much more. <br />
      </Typography>
      <SpacerV />

      <YTextField
        fullWidth
        name="email"
        label="Email"
        control={control}
        isRequired
        isEmail
      />

      <YTextField
        fullWidth
        isRequired
        minLength={8}
        name="password"
        type="password"
        label="Password"
        control={control}
      />

      <SpacerV size="small"/>

      <YButtonPrimary fullWidth onPress={handleSubmit(userProvider.login)} >
        SIGN IN
      </YButtonPrimary>
      <SpacerV size="small"/>

      <YButtonLink to="/forgot-password">Forgot Password?</YButtonLink>
      <SpacerV size="large"/>

      <Typography variant="body1" >
        Don't have an accoutn yet?
      </Typography>
      <YButtonLink to="/sign-up">Create Account</YButtonLink>
    </div>
  );
});

export default Signin;

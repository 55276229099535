import { AxiosInstance } from 'axios';
import { Api, createApi } from './api';
import { GameRequest }from 'types';

class Game {
  public path: String = 'game/v1';
  public client?: AxiosInstance | undefined;

  constructor() {
    const port = process.env['REACT_APP_GAME_API_PORT'];
    this.client = createApi(this.path, port);
  }

  /* Posts game data to game service */
  public createGame = async (accountId: string, payload: GameRequest): Promise<any> => {
    const path = `/accounts/${accountId}/games`;
    return await Api.post(this.client!, path, payload);
  };

  public getForCampaign = async (accountId: string, campaignId: string): Promise<any> => {
    const path = `/accounts/${accountId}/games?campaignIds=${campaignId}`;
    return await Api.get(this.client!, path);
  };

  /* Updates game data to game service */
  public updateGame = async (gameId: string, data: GameRequest): Promise<any> => {
    const path = `/games/${gameId}`;
    return await Api.patch(this.client!, path, data);
  };
}

export default Game;




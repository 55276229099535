import React from 'react';
import {
  Box,
} from '@mui/material';
import {
  YTextField,
  YButtonPrimary
} from 'components/common';
import Account from 'types/Account';
import {Controller, useForm} from 'react-hook-form';
import InputMask from "react-input-mask";

interface Props {
  onSave: (data: any) => Promise<void>;
  businessInfo: Partial<Account> | undefined;
}

const EditCompany = (props: Props) =>  {

  const defaultValues = props.businessInfo;
  const { handleSubmit, control, formState } = useForm({ defaultValues });

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <div>
        <YTextField
          key="name"
          fullWidth
          name="name"
          label="Name"
          control={control}
          isRequired
        />

        <YTextField
          key="ein"
          fullWidth
          name="ein"
          label="EIN"
          control={control}
          isRequired
        />

        <YTextField
          key="address"
          fullWidth
          name="address"
          label="Address"
          control={control}
          isRequired
        />

        <YTextField
          key="address2"
          fullWidth
          name="address2"
          label="Address 2"
          control={control}
        />

        <YTextField
          key="city"
          fullWidth
          name="city"
          label="City"
          control={control}
          isRequired
        />

        <YTextField
          key="state"
          fullWidth
          name="state"
          label="State"
          control={control}
          isRequired
        />

        <YTextField
          key="zip"
          fullWidth
          name="zip"
          label="Zip"
          control={control}
          isRequired
        />

        <YTextField
          key="email"
          fullWidth
          name="email"
          label="Email"
          control={control}
          isRequired
        />

        <Controller
          name="phone"
          control={control}
          rules={{
            validate: value => value?.replace(/[^\d]/g, '').length === 11 || 'Please enter a full phone number.',
          }}
          render={({ field }) => (
            <InputMask mask="+1(999) 999-9999" value={field.value} onChange={field.onChange}>
              {
                // @ts-ignore
                (inputProps: any) => <YTextField
                  fullWidth
                  name="phone"
                  label="Phone"
                  control={control}
                  isRequired
                />
              }
            </InputMask>
          )}
        />
      </div>

      <Box>
        <YButtonPrimary
          fullWidth
          onPress={handleSubmit(props.onSave)}
          disabled={!formState.isValid}
        >
          Save
        </YButtonPrimary>
      </Box>
    </Box>
  );
};

export default EditCompany;


import { AxiosInstance } from 'axios';
import { Api, createApi } from './api';

class Document {
  public path: String = 'document/v1';
  public client?: AxiosInstance | undefined;

  constructor() {
    const port = process.env['REACT_APP_DOCUMENT_API_PORT'];
    this.client = createApi(this.path, port);
  }

  /* Returns list of of orders for specific account owner */
  public uploadImage = async (file: File): Promise<any> => {
    const path = `/images`;
    const formData = new FormData();
    formData.append('file', file);

    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    Object.assign(this.client!, headers);
    return await Api.post(this.client!, path, formData);
  };

  /* Returns list of of orders for specific account owner */
  public uploadVideo = async (campaignId: string, file: File): Promise<any> => {
    const path = `/videos`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('campaignId', campaignId);

    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    Object.assign(this.client!, headers);
    return await Api.post(this.client!, path, formData);
  };
}

export default Document;


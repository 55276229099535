class Category {
  public id: string;
  public name: string;
  public parentId: string;
  public categories: Category[];

  public constructor(data: any) {
    this.id = data.id || '';
    this.name = data.name || '';
    this.parentId = data.parentId || '';

    if (data.categories && data.categories.length > 0) {
      this.categories = data.categories.map((cat: any) => new Category(cat))
    } else {
      this.categories = [];
    }
  }

  static fromJsonList = (data: any) => {
    if (data.length === 0) {
      return;
    }

    return data.map((cat: any) => new Category(cat));
  }
}

export default Category;


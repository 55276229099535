import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { Credentials } from 'types/Credentials';
import RootProvider from './RootProvider';
//import dashboardStore from './dashboardStore';

class CredentialsProvider {
  root: RootProvider;

  credentials?: Credentials;
  initiated = false;
  tokenExpired = false;
  tokenRefreshFlag = 0; // Flag for reloading components after token is refreshed
  refreshingToken = false;




  constructor(root: RootProvider) {
    this.root = root;

    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: 'CredentialProvider',
      properties: ['credentials'],
      storage: window.localStorage,
    }).then(() => {
      this.initiated = true;
    });
  }
}

export default CredentialsProvider;

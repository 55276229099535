import React from 'react';
import {
  Box,
  Card,
  Theme,
  Divider,
  Typography,
} from '@mui/material';
import {
  Cancel, CancelOutlined, Clear,
  Edit
} from '@mui/icons-material';
import { useTheme } from '@mui/styles';

interface Props {
  title: string;
  onEdit?: () => void;
  onDelete?: () => void;
  children: React.ReactNode;
}

export const useStyles: Function = (theme: Theme) => ({
  root: {
    boxShadow: 'none'
  },
  header: {
    padding: 2,
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
    padding: 2
  }
});

export const InfoPanel: React.FC<Props> = props => {
  const sx = useStyles(useTheme());

  return (
    <Card sx={sx.root}>
      <Box sx={sx.header}>
        <Typography sx={{flexGrow: 1}} variant="h4">{props.title}</Typography>

        {props.onEdit &&
          <Edit sx={{cursor: 'pointer'}} color="primary" onClick={props.onEdit} />
        }

        {!props.onEdit && props.onDelete && (
          <Clear sx={{cursor: 'pointer'}} onClick={props.onDelete} />
        )}
      </Box>
      <Divider />
      <Box sx={sx.content}>
        {props.children}
      </Box>
    </Card>
  );
}

export default (InfoPanel);


import UserProvider from './UserProvider';
import CredentialsProvider from './CredentialsProvider';
import ErrorProvider from './ErrorProvider';
import ToastProvider from './ToastProvider';
import BusinessProvider from './BusinessProvider';
import UsersProvider from './UsersProvider';
import OrderProvider from './OrderProvider';
import CampaignProvider from './CampaignProvider';
import DrawerProvider from './DrawerProvider';
import TutorialProvider from './TutorialProvider';
import WizardProvider from "./WizardProvider";

/**
 * The root store initializes all other stores and holds references
 * to them. When constructing the other stores, it passes itself
 * as an argument to their constructor. This way, stores can communicate
 * with each other via the root store.
 */
export default class RootProvider {
  public userProvider: UserProvider;
  public credentialsProvider: CredentialsProvider;
  public errorProvider: ErrorProvider;
  public toastProvider: ToastProvider;
  public businessProvider: BusinessProvider;
  public usersProvider: UsersProvider;
  public orderProvider: OrderProvider;
  public campaignProvider: CampaignProvider;
  public drawerProvider: DrawerProvider;
  public tutorialProvider: TutorialProvider;
  public wizardProvider: WizardProvider;

  public navigate: Function;

  public routeAfterLogin = '/dashboard';

  constructor(navigate: Function) {
    this.navigate = navigate;
    this.userProvider = new UserProvider(this);
    this.credentialsProvider = new CredentialsProvider(this);
    this.errorProvider = new ErrorProvider(this);
    this.toastProvider = new ToastProvider(this);
    this.businessProvider = new BusinessProvider(this);
    this.usersProvider = new UsersProvider(this);
    this.orderProvider = new OrderProvider(this);
    this.campaignProvider = new CampaignProvider(this);
    this.drawerProvider = new DrawerProvider(this);
    this.tutorialProvider = new TutorialProvider(this);
    this.wizardProvider = new WizardProvider(this);
  }
}

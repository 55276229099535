import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import TopBar from './TopBar';

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    sidebar: {
      width: 300,
      height: 600,
      color: 'green',
      backgroundColor: theme.palette.primary.main
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    }
}));

interface Props {
  children?: React.ReactNode;
}

const DashboardLayout: React.FC<Props> = props => {

  const classes = useStyles();
  const { children, } = props;
  return (
    <div className={classes.root}>
      <TopBar />

      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
        <Outlet />
      </main>
    </div>
  );
}

export default DashboardLayout;

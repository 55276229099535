import Percent from 'models/Percent';
import Amount from 'models/Amount';

class Reward {
  public id: string;
  public accountId: string;
  public campaignId: string;
  public gameId: string;
  public name: string;
  public type: string;
  public createdAt: string;
  public amount: Amount;
  public percent: Percent;
  public total: number;
  public claimsPerPerson: number;
  public minOrderAmount: Amount;
  public freeItems: Array<string>;
  public duration: number;

  public constructor(data: any) {
    this.id = data.id || '';
    this.accountId = data.accountId || '';
    this.campaignId = data.campaignId || '';
    this.gameId = data.gameId || '';
    this.name = data.name;
    this.createdAt = data.createdAt || '';
    this.type = data.type || '';
    this.amount = new Amount(data.amount) || new Amount({});
    this.percent = new Percent(data.percent) || new Percent({});
    this.total = data.total || 0;
    this.claimsPerPerson = data.claimsPerPerson || 0;
    this.minOrderAmount = new Amount(data.minOrderAmount) || new Amount({});
    this.freeItems = data.freeItems || [];
    this.duration = data.duration || 0;
  }
}

export default Reward;


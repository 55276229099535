import difficulties from 'data/gameDifficulties';

class Game {
  public id: string;
  public accountId: string;
  public campaignId: string;
  public name: string;
  public description: string;
  public createdAt: string;
  public type: string;
  public settings: GameSettings | null;
  public difficulty: string = '';

  public constructor(data: any) {
    this.id = data.id || '';
    this.accountId = data.accountId || '';
    this.campaignId = data.campaignId || '';
    this.name = data.name;
    this.description = data.description;
    this.createdAt = data.createdAt || '';
    this.type = data.type || 'puzzle';
    this.settings = data.settings ? new GameSettings(data.settings) : null;
    if (this.settings) {
      this.difficulty = this.settings.difficultyLabel();
    }
  }

  public static difficultyByLabel = (label: string) => {
    return difficulties.find(d => d.label === label);
  }
}


class GameSettings {
  public image: string;
  public pieces: number;
  public duration: number;
  public rotation: boolean;

  public constructor(data: any) {
    this.image = data.image || '';
    this.pieces = data.pieces || 0;
    this.duration = data.duration || 0;
    this.rotation = data.rotation || false;
  }

  public difficulty = () => {
    return difficulties.find((d: any) => d.id === `${this.pieces}x${this.duration}`);
  }

  public difficultyLabel = () => {
    const difficulty = difficulties.find((d: any) => d.id === `${this.pieces}x${this.duration}`);
    return (difficulty && difficulty.label) || '';
  }
}

export default Game;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, Navigate } from 'react-router-dom';
import { useRootProvider } from 'providers/RootContext';

const AuthRoute = observer(() => {
  const { userProvider } = useRootProvider();

  if (userProvider.tokenAlmostExpired) {
    console.log('refreshing token as almost expired');
    userProvider.refreshToken();
  }

  if (userProvider.loggedIn) {
    return <Outlet />
  }

  return <Navigate to="/sign-in" />

});

export default AuthRoute;

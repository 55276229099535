import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@mui/material";
import {InfoPanel, YAvatar, YCenter} from "../../components/common";
import {useRootProvider} from "../../providers/RootContext";

export const BrandItem = ({ brand }: {brand: any}) => {
  const { businessProvider: bp} = useRootProvider();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    bp.deleteBrand(brand.id);
    setOpen(false);
  };

  return (
    <Grid item xs={6} sm={4}>
      <InfoPanel title={brand.name} onDelete={handleClickOpen}>
        <YCenter minHeight="350">
          <YAvatar image={brand.logo} name={brand.name} size={124} />
        </YCenter>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this brand?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={{color: 'black'}}>
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </InfoPanel>
    </Grid>
  );
};
import React from 'react';
import { Controller } from 'react-hook-form';
import { AdapterDayjs  } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider  } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Box, InputAdornment, TextField } from '@mui/material';
import { Event } from '@mui/icons-material';

interface Props {
  control: any;
  startLabel: string;
  endLabel: string;
  startName?: string;
  endName?: string;
  onChange?: (val: any) => void;
  disablePast?: boolean;
  setStartsAt?: (val: string) => void;
  setEndsAt?: (val: string) => void;
  minDate?: Date;
}

export const YDateRangePicker: React.FC<Props> = props => {

  //const [value, setValue] = React.useState<DateRange<Dayjs>>([null, null]);

  //const handleChange = (newValue: any) => {
    //setValue(newValue);
    //props.onChange(newValue);
  //}

  return (
    <Controller
      control={props.control}
      name="daterange"
    render={({ field: {ref, onBlur, onChange, value, name, ...field}, fieldState }) => (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={{ start: props.startLabel, end: props.endLabel }}
      >
        <DateRangePicker
          {...field}
          disablePast={props.disablePast}
          value={value || [null, null]}
          minDate={props.minDate}
          onChange={(newValue: any) => {
            onChange(newValue);
            if (props.setStartsAt && newValue[0]) {
              props.setStartsAt(newValue[0].toISOString());
            }
            if (props.setEndsAt && newValue[1]) {
              props.setEndsAt(newValue[1].toISOString());
            }
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField
                {...startProps}
                fullWidth
                name={props.startName ? props.startName : "startDate"}
                label={props.startLabel}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><Event /></InputAdornment>,
                }}
                onChange={(e) => {
                  if (props.setStartsAt) {
                    props.setStartsAt(e.target.value);
                  }
                }}
              />
              <Box sx={{ mx: 1 }}></Box>
              <TextField
                {...endProps}
                fullWidth
                name={props.endName ? props.endName : "endDate"}
                label={props.endLabel}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><Event /></InputAdornment>,
                }}
                onChange={(e) => {
                  if (props.setEndsAt) {
                    props.setEndsAt(e.target.value);
                  }
                }}
                //InputProps={{ classes } as Partial<OutlinedInputProps>}
              />
            </React.Fragment>
          )}
        >

        </DateRangePicker>
      </LocalizationProvider>
    )}
    />
  );
}

export default YDateRangePicker;


import React, {useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useRootProvider } from 'providers/RootContext';
import {
  Box,
  MenuItem,
} from '@mui/material';
import {
  YTextField,
  YTagsField,
  YButtonPrimary,
  YDateRangePicker
} from 'components/common';


const CreateCampaign = observer(() => {
  const { businessProvider: bp, campaignProvider: cp, wizardProvider: wp } = useRootProvider();
  const [startsAt, setStartsAt] = React.useState<string | undefined>(undefined);
  const [endsAt, setEndsAt] = React.useState<string | undefined>(undefined);

  const form = useForm({mode: 'onChange'});

  useEffect(() => {
    if (bp.brands === undefined) {
      bp.getBrands();
    }
  }, [bp]);

  useEffect(() => {
    if (isFormValid()) {
      wp.setOnNext(async () => {
        await form.handleSubmit(cp.saveCampaign)();
      });
    } else {
      wp.setOnNext(undefined);
    }
  }, [bp, cp, startsAt, endsAt, form]);

  useEffect(() => {
    form.reset(cp.defaults.campaign.defaultValues);

    const campaign = getCampaign();
    if (campaign) {
      setStartsAt(campaign.startsAt);
      setEndsAt(campaign.endsAt);
      form.reset({
        ...form.getValues(),
        startsAt: campaign.startsAt,
        endsAt: campaign.endsAt,
      });
      form.trigger('startsAt');
      form.trigger('endsAt');
    }
  }, [cp, form]);

  const isFormValid = () => {
    if (!form.formState.isValid) {
      return false;
    }

    if (!startsAt || !endsAt){
      return false;
    }

    if (startsAt >= endsAt) {
      return false;
    }

    return true;
  }

  const getCampaign = () => {
    if (cp.ctx?.campaign?.id) {
      return cp.getCampaign(cp.ctx.campaign.id);
    }

    return undefined;
  }

  const getMinDate = () => {
    const createdAt = getCampaign()?.createdAt;
    if (createdAt) {
      return new Date(createdAt);
    }

    return new Date(Date.now());
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <div>
        <YTextField
          fullWidth
          select
          name="brandId"
          label="Select Brand"
          control={form.control}
          isRequired
        >
          {bp.brands && bp.brands.map(b => (
            <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
          ))}
        </YTextField>

        <YTextField
          fullWidth
          name="name"
          label="Name"
          control={form.control}
          isRequired
        />

        <YTextField
          fullWidth
          name="description"
          label="Description"
          multiline
          rows={1}
          control={form.control}
          isRequired
        />

        <YDateRangePicker
          control={form.control}
          startLabel="Start Date"
          endLabel="End Date"
          startName="startsAt"
          endName="endsAt"
          minDate={getMinDate()}
          setStartsAt={setStartsAt}
          setEndsAt={setEndsAt}
        />

        <YTagsField
          fullWidth
          onModify={() => { }}
          variant="outlined"
          name="tags"
          label="Tags"
          helperText="Type words that describe the dish on the puzzle image"
          control={form.control}
        />
      </div>
    </Box>
  );
})

export default CreateCampaign;


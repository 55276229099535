import React from 'react';
import { BrowserRouter as Router  } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Main from './Main';
import { theme } from './theme';
import { RootContextProvider } from 'providers/RootContext';
import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
    'd58e7ea8b52fcda8b3529b232ba67dd6Tz03NjgyMSxFPTE3MjkxODU5MDYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <RootContextProvider>
          <Main />
        </RootContextProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;

import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import {
  Link,
  ButtonProps
} from '@mui/material';

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      cursor: 'pointer'
    },
}));

interface Props {
  to?: string;
  onPress?: () => void;
}

export const YButtonLink: React.FC<Props & ButtonProps> = props => {

  const classes = useStyles();
  const {  children, to, onPress } = props;

  if (to) {
    return (
      <Link
        component={RouterLink}
        className={classes.root}
        to={to}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link onClick={onPress} className={classes.root}>{children}</Link>
  )
}

export default (YButtonLink);

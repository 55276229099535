import React, { useEffect } from 'react';
import { useRootProvider } from 'providers/RootContext';
import {
  CircularProgress,
  Typography,
  Box
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import {
  YCenter,
  SpacerV,
  YButtonSecondary,
  YGrid,
  YCard,
} from 'components/common';

const Users = observer(() =>  {

  const { businessProvider: bp } = useRootProvider();

  const columns = [
    { field: 'name', headerName: 'Name', flex: 0.25 },
    { field: 'familyName', headerName: 'Last Name', flex: 0.25 },
    { field: 'email', headerName: 'Email', flex: 0.25 },
    { field: 'phone', headerName: 'Phone', flex: 0.25 },
  ];

  useEffect(() => {
    if (bp.team === undefined) {
      bp.getAccountTeam();
    }
  }, [bp]);

  return (
    <div>
      {bp.isFetching &&
        <YCenter>
          <CircularProgress />
        </YCenter>
      }

      {!bp.isFetching && bp.team && bp.team.length === 0 &&
        <YCenter>
            <Typography variant="h6">No team members here. Add one now.</Typography>
            <SpacerV size="30" />
            <YButtonSecondary onPress={() => {}}>Add a Team Member</YButtonSecondary>
        </YCenter>
      }

      { bp.team !== undefined &&
      <Box sx={{width: '100%', height: '100%'}}>
        <YCard>
          <YGrid
            columns={columns}
            rows={bp.team as any}
          />
        </YCard>
      </Box>
      }
    </div>
  );
});

export default Users;


import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useRootProvider } from 'providers/RootContext';
import {
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import SpacerV from 'components/common/SpacerV';
import YTextField from 'components/common/YTextField';
import YButtonPrimary from 'components/common/YButtonPrimary';
import { useNavigate } from 'react-router-dom';

const useStyles: Function = makeStyles((theme: Theme) => createStyles({
  root: {
    textAlign: 'center'
  },
  title: {
    color: theme.palette.text.secondary,
  }
}));

interface Props {}

function ForgotPassword(props: Props) {
  const classes = useStyles();
  const { userProvider } = useRootProvider();
  const navigate = useNavigate();

  const defaultValues = {
    email: '',
  }

  const {control, getValues } = useForm({ defaultValues });

  const handleForgotPassword = async () => {
    try {
      const { email } = getValues();
      await userProvider!.forgotPassword(email);
      userProvider.forgotPasswordFlow = true;
      navigate('/verification');
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Reset Password
      </Typography>
      <SpacerV size="small" />

      <Typography variant="body1" >
        Enter your email below to reset your password
      </Typography>
      <SpacerV />

      <YTextField
        fullWidth
        label="Enter Email"
        name="email"
        control={control}
        isEmail
        isRequired
      />

      <SpacerV size="small"/>

      <YButtonPrimary fullWidth onPress={handleForgotPassword} >
        Continue
      </YButtonPrimary>
      <SpacerV size="large"/>

    </div>
  );
}

export default ForgotPassword;


import React, { CSSProperties } from 'react';

interface Props {
  size?: string;
}

export const SpacerV: React.FC<Props> = props => {

  const { size } = props;
  let height;
  switch (size) {
    case "small":
      height = 10;
      break;;
    case "large":
      height = 50;
      break;;
    default:
      height = 30;
  }

  const styles: CSSProperties = {
    height: height
  }

  return (
    <div style={styles}></div>
  );
}

export default (SpacerV);

import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useRootProvider} from 'providers/RootContext';
import {
  Box,
  Chip,
  Link,
  Switch,
  Theme,
  Typography,
  TextField
} from "@mui/material";
import {
  YButtonPrimary
} from 'components/common';
import {createStyles, makeStyles} from "@mui/styles";
import {useParams} from "react-router-dom";
import InfoPanel from "components/common/InfoPanel";
import InfoPanelItem from "../../components/common/InfoPanelItem";
import {Brand} from "../../types";
import Api from 'api/api';
import {Image} from "@mui/icons-material";
import {toJS} from "mobx";

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      marginBottom: 24,
      borderRadius: 20,
      padding: 8,
    },
    cardHeader: {
      paddingBottom: 8,
    },
    cartContent: {
      paddingTop: 8,
    },
    cardRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      alignItems: 'center',
    },
    cardRowLeftItem: {
      flex: 1,
      color: "#6E7495",
      fontWeight: 200,
      textAlign: 'right',
      paddingRight: theme.spacing(4),
    },
    cardRowRightItem: {
      flex: 3,
    },
    formDrawerInput: {
      marginTop: 24,
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: "flex-start",
      alignContent: "center",
      alignItems: "center",
    }
  }));

export const Details = observer(() => {
  const {campaignProvider: cp, drawerProvider: dp, businessProvider: bp} = useRootProvider();
  const [brand, setBrand] = useState<any>(undefined);

  let {id} = useParams();
  const [campaign, setCampaign] = useState<any>();
  const [categories, setCategories] = useState<any>([]);

  const classes = useStyles();

  useEffect(() => {
    cp.getCampaigns().then(() => {
      const c = cp.getCampaign(id);
      setCampaign(c);
      cp.getRewardForCampaign(id).then(() => {
        let newCampaign = {...c, reward: cp.reward};
        setCampaign(newCampaign);
      });
    });
  }, [cp]);

  useEffect(() => {
    bp.getLocations();
  }, [bp]);

  useEffect(() => {
    setCategories(campaign?.categories);
    dp.setValues(campaign);

    const brandId = campaign?.brandId;
    if (brandId !== undefined) {
      const brand = cp.brands.get(brandId);
      setBrand(brand);
    }
  }, [campaign]);

  const saveCampaignCallback = () => {
    Api.campaign.update(campaign!.id, dp.values).then(() => {
      cp.getCampaigns().then(() => {
        setCampaign(cp.getCampaign(id));
      });
    });
    dp.close();
    dp.reset();
  }

  const onClickEditCampaignInfo = () => {
    const content = (
      <div>
        <div key={"name"} className={classes.formDrawerInput!}>
          <TextField
            label={"Name"}
            fullWidth
            autoComplete="new-password"
            defaultValue={dp.values.name || ''}
            onChange={(e) => {
              let newFormValues = {...dp.values, "name": e.target.value};
              dp.setValues(newFormValues);
            }}
          />
        </div>

        <div key={"description"} className={classes.formDrawerInput!}>
          <TextField
            label={"Description"}
            fullWidth
            autoComplete="new-password"
            defaultValue={dp.values.description || ''}
            onChange={(e) => {
              let newFormValues = {...dp.values, "description": e.target.value};
              dp.setValues(newFormValues);
            }}
          />
        </div>
      </div>
    );

    dp.drawerOpen(renderDrawerFrame(content, saveCampaignCallback), "Edit campaign info");
  };

  const onClickEditCategories = () => {
    const content = (
      <div>
        TODO
      </div>
    );

    dp.drawerOpen(
      renderDrawerFrame(content, saveCampaignCallback),
      "Edit categories",
    );
  }

  const renderDrawerFrame = (content: React.ReactNode, action: () => void) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        {content}
        <Box>
          <YButtonPrimary
            fullWidth
            onPress={action}
          >
            Save
          </YButtonPrimary>
        </Box>
      </Box>
    )
  }

  const onClickEditOtherInfo = () => {
    const content = (
      <div>
        <div key={"promoteBrand"} className={classes.formDrawerInput!}>
          <div className={classes.flexRow!}>
            <Typography>Promote brand</Typography>
            <Switch defaultChecked={dp.values?.promoteBrand || false} onChange={(newVal) => {
              const checked = newVal.target.checked;
              let newFormValues = {...dp.values, "promoteBrand": checked};
              dp.setValues(newFormValues);
            }}/>
          </div>
        </div>

        <div key={"createdAt"} className={classes.formDrawerInput!}>
          <TextField
            label={"Created at"}
            fullWidth
            autoComplete="new-password"
            defaultValue={dp.values?.createdAt || ''}
            onChange={(e) => {
              let newFormValues = {...dp.values, "createdAt": e.target.value};
              dp.setValues(newFormValues);
            }}
          />
        </div>

        <div key={"status"} className={classes.formDrawerInput!}>
          <TextField
            label={"Status"}
            fullWidth
            autoComplete="new-password"
            defaultValue={dp.values?.status || ''}
            onChange={(e) => {
              let newFormValues = {...dp.values, "status": e.target.value};
              dp.setValues(newFormValues);
            }}
          />
        </div>
      </div>
    );

    dp.drawerOpen(
      renderDrawerFrame(content, saveCampaignCallback),
      "Edit other info",
    );
  }

  const onClickEditFineprint = () => {
    const content = (
      <div>
        <div key={"fineprint"} className={classes.formDrawerInput!}>
          <TextField
            label={"Fineprint"}
            fullWidth
            autoComplete="new-password"
            defaultValue={dp.values?.fineprint || ''}
            onChange={(e) => {
              let newFormValues = {...dp.values, "fineprint": e.target.value};
              dp.setValues(newFormValues);
            }}
          />
        </div>
      </div>
    );
    dp.drawerOpen(
      renderDrawerFrame(content, saveCampaignCallback),
      "Edit fineprint",
    );
  }

  return (
    <div style={{width: "100%", display: "flex"}}>
      <div style={{width: '48%', marginRight: '1em'}}>
        <div style={{marginBottom: '1em'}}>
          <InfoPanel title={"Campaign info"} onEdit={onClickEditCampaignInfo}>
            <InfoPanelItem label={"Name"} value={campaign?.name || ''}/>
            <InfoPanelItem label={"Description"} value={campaign?.description || ''}/>
            <InfoPanelItem label={"Brand"}>
              {brand !== undefined && (
                <Link
                  color="primary"
                  sx={{textTransform: 'none', textDecoration: 'none', fontWeight: 600}}
                  href={`/brands/${brand!.id}`}
                >{brand!.name}
                </Link>
              )}
            </InfoPanelItem>
            <InfoPanelItem label={"Duration"} value={campaign?.duration || ''}/>
            <InfoPanelItem label={"tags"}>
              <div style={{display: "flex", alignItems: "center", alignContent: "center"}}>
                {campaign?.tags?.map((tag: any, idx: number) => {
                  return (
                    <Chip
                      key={idx}
                      style={{marginRight: '0.5em'}}
                      color="primary"
                      label={tag}
                      onClick={() => {
                      }}
                    />
                  );
                })}
              </div>
            </InfoPanelItem>
          </InfoPanel>
        </div>
        <div style={{marginBottom: '1em'}}>
          <InfoPanel title={"Categories"} onEdit={onClickEditCategories}>
            <div style={{display: "flex", paddingLeft: '2em'}}>
              {categories?.map((category: any, idx: number) => {
                return (
                  <Chip
                    key={idx}
                    style={{marginRight: '0.5em'}}
                    color="primary"
                    label={category.name}
                    onClick={() => {
                    }}
                  />
                );
              })}
            </div>
          </InfoPanel>
        </div>
        <div style={{marginBottom: '1em'}}>
          <InfoPanel title={"Other info"} onEdit={onClickEditOtherInfo}>
            <InfoPanelItem label={"Promote brand"}>
              <Switch checked={campaign?.promoteBrand || false} onChange={(newVal) => {
                const checked = newVal.target.checked;
                Api.campaign.update(campaign!.id, {promoteBrand: checked}).then(() => {
                  cp.getCampaigns();
                });
              }}/>
            </InfoPanelItem>
            <InfoPanelItem label={"Created At"} value={campaign?.createdAt}/>
            <InfoPanelItem label={"Status"} value={campaign?.status}/>
          </InfoPanel>
        </div>
        <div style={{marginBottom: '1em'}}>
          <InfoPanel title={"Campaign locations"}>
            {campaign?.locations?.map((location: any, idx: any) => {
              const businessLocation = bp?.locationMap?.get(location.id);
              if (businessLocation?.name && businessLocation?.address) {
                return (
                  <InfoPanelItem key={idx} label={businessLocation!.name} value={businessLocation!.address}/>
                );
              }

              return <div key={idx}/>
            })}
          </InfoPanel>
        </div>
      </div>
      <div style={{width: '48%'}}>
        <div style={{marginBottom: '1em'}}>
          <InfoPanel title={"Game setup"}>
            <img src={campaign?.image} style={{width: '100%', height: 'auto'}}/>
          </InfoPanel>
        </div>
        <div style={{marginBottom: '1em'}}>
          <InfoPanel title={"Reward"}>
            {campaign?.reward && (<InfoPanelItem label={"Name"} value={campaign!.reward.name}/>)}
            {campaign?.reward && (<InfoPanelItem label={"Type"} value={campaign!.reward.type}/>)}
            {campaign?.reward?.percent?.formatted &&
              (<InfoPanelItem label={"Percent"} value={campaign!.reward.percent.formatted}/>)}
            {campaign?.reward?.minOrderAmount?.formatted && (
              <InfoPanelItem label={"Min order amount"} value={campaign!.reward.minOrderAmount?.formatted}/>)}
          </InfoPanel>
        </div>
        <div style={{marginBottom: '1em'}}>
          <InfoPanel title={"Fineprint"} onEdit={onClickEditFineprint}>
            {campaign?.finePrint && campaign!.finePrint.map(
              (str: any, idx: number) => {
                return (
                  <Typography key={idx} color="textSecondary"
                              style={{fontWeight: 600, marginLeft: '1em'}}>• {str || ''}</Typography>
                )
              }
            )}
          </InfoPanel>
        </div>
      </div>
    </div>
  );
});


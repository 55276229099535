class LocationSettings {
  public deliveryEnabled: boolean;
  public deliveryFee: number;
  public deliveryRadius: number;

  public constructor(data:any) {
    this.deliveryEnabled = data?.deliveryEnabled || false;
    this.deliveryFee = data?.deliveryFee || 0;
    this.deliveryRadius = data?.deliveryRadius || 0;
  }
}

class Location {
  public id: string;
  public accountId: string;
  public brandId: string;
  public brandName: string;
  public name: string;
  public email: string;
  public phone: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: string;
  public longitude: string;
  public latitude: string;
  public settings: LocationSettings;

  public constructor(data: any) {
    this.id = data.id || '';
    this.accountId = data.account?.id || '';
    this.brandId = data.brand?.id || '';
    this.brandName = data.brand?.name || '';
    this.name = data.name;
    this.email = data.email;
    this.phone = data.phone;
    this.address = data.address;
    this.city = data.city || '';
    this.state = data.state || '';
    this.zip = data.zip || '';
    this.longitude = data.longitude || '';
    this.latitude = data.latitude || '';
    this.settings = new LocationSettings(data.settings || {});
  }

  static fromJsonList = (data: any) => {
    if (data.length === 0) {
      return [];
    }

    return data.map((loc: any) => new Location(loc));
  }
}

export default Location;


import React, {useEffect, useRef, useState} from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import {
  Typography,
  Grid,
  Button,
  Box
} from '@mui/material';
import {
  CloudUploadOutlined
} from '@mui/icons-material';
import YAvatar from './YAvatar';

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '10px 0',
      cursor: 'pointer',
      border: '3px #BEC2E3 dashed',
      borderRadius: 16,
      padding: 30,
      textAlign: 'center',
    },
    icon: {
      height: 48,
      width: 48,
      color: '#BEC2E3',
      marginRight: 10
    },
    text: {
      color: '#BEC2E3',
      fontWeight: 600
    }
}));

interface Props {
  display?: string;
  width?: number;
  height?: number;
  isImage?: boolean;
  onSelect: Function;
  control?: any;
  setValue?: any;
}

export const Uploader: React.FC<Props> = props => {

  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const { display, width, height } = props;
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState<File>();
  const [preview, setPreview] = useState('');
  const theme = useTheme();

  let styles = {};
  if (width) {
    styles = {...styles, width};
  }

  if (height) {
    styles = {...styles, height};
  }

  const handleFile = (file: File) => {
    setFile(file);
    setPreview(URL.createObjectURL(file));
    props.onSelect(file);
  }

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFile(e.dataTransfer.files[0]);
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      handleFile(e.target.files[0]);
      if (props.setValue) {
        props.setValue('file', e.target.files[0]);
      }
    }
  }

  const onClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
      console.log('clicked input ref: ', inputRef.current);
    }
  }

  useEffect(() => {
    // This function will be triggered when a file is selected
    const handleChange = (event: any) => {
      const file = event.target.files[0];
      if (!file) return;

      handleFile(file);  // Assuming handleFile processes the file
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('change', handleChange);
    }

    // Clean up function to remove the event listener when the component unmounts
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('change', handleChange);
      }
    };
  }, []);  // Empty dependency array to ensure this effect runs only once after the initial render


  const renderInput = () => {
    if (props.control) {
      return (
        <Controller name="file" control={props.control} render={({
          field: {onChange, onBlur, value, ...field},
          fieldState: {invalid, error},
          formState
        }) => (
              <input {...field} hidden accept={props.isImage ? 'image/*' : '*'} type="file" onChange={handleChange} ref={inputRef} />
        )} />
      )
    } else {
      return (
        <input hidden accept={props.isImage ? 'image/*' : '*'} type="file" onChange={handleChange} ref={inputRef} />
      )
    }
  }

  return (
    <div>
      <Box className={classes.root}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={onClick}
        style={dragActive ? { borderColor: theme.palette.common.black } : {}}
      >
        {renderInput()}
        <Grid container style={styles} alignItems="center" justifyContent="center" direction={display === 'block' ? 'column' : 'row'}>
          <Grid item>
            {props.isImage && preview &&
              <YAvatar image={preview} name="logo" size={100} />
            }

            {!preview &&
              <CloudUploadOutlined className={classes.icon} />
            }

          </Grid>
          <Grid item>
            {file &&
              <div>
                <Typography className={classes.text} variant="body1">
                  {file.name}
                </Typography>
                <Button >Change</Button>
              </div>
            }

            {!file &&
              <Typography className={classes.text} variant="body1">
                Drop a file or click to upload.
              </Typography>
            }
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Uploader;


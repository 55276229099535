class BusinessMock {
  static listAccounts = () => {
    return [
      {
          id: "b26d5673-e4e4-4af3-b2c8-7dbc1741660a",
          name: "Darden Restaurants",
          logo: "https://www.darden.com/themes/custom/darden/images/darden-logo.svg"
      },
      {
          id: "b26d5673-e4e4-4af3-b2c8-7dbc1783k2",
          name: "Bloomin' Brands",
          logo: "https://www.bloominbrands.com/resourcepackages/bloominbrands/assets/dist/images/bbi-logo.svg"
      },
      {
          id: "b26d5673-e4e4-4af3-b2c8-sk9283js92kf",
          name: "Brinker International",
          logo: "https://www.startpage.com/av/proxy-image?piurl=https%3A%2F%2Fmma.prnewswire.com%2Fmedia%2F726858%2FBrinker_Intl_Logo.jpg"
      },
      {
          id: "e311c9b6-d8a7-41a7-b553-1bbb17908d28",
          name: "Dine Brands",
          logo: "https://www.dinebrands.com/-/media/dinebrands/images/logos/dine-brandslogo2x.png"
      }
    ];
  }

  static listUsers = () => {
    return [
      {
        firstName: "Tony",
        lastName: "Stark",
        email: "ironman@marvel.com",
        phone: "+15553030203"
      },
      {
        firstName: "Bruce",
        lastName: "Banner",
        email: "hulk@marvel.com",
        phone: "+15553030203"
      },
      {
        firstName: "Thor",
        lastName: "Son of Odin",
        email: "thor@marvel.com",
        phone: "+15553030203"
      },
      {
        firstName: "Natalia",
        lastName: "Romanov",
        email: "blackwidow@marvel.com",
        phone: "+15553030203"
      }
    ]
  }

}

export default BusinessMock;

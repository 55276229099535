export const CampaignEmptyForm: object = {
  name: '',
  brandId: '',
  description: '',
  startsAt: '',
  endsAt: '',
  daterange: [null, null],
  tags: []
}

export const CampaignAssetsEmptyForm: object = {
  image: '',
  video: ''
}

export const CategoriesEmptyForm: object = {
  cuisines: [],
  diets: [],
}

export const GameEmptyForm: object = {
  difficulty: '',
  image: '',
  rotation: false
}

export const RewardEmptyForm: object = {
    name: '',
}

export const LocationsEmptyForm: object = {
    locations: {},
}

export const ExtrasEmptyForm: object = {
    name: '',
}

import React from 'react';
import {
  InputBase,
  Theme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

import {
  Search
} from '@mui/icons-material';

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: alpha(theme.palette.common.black, 0.05),
			'&:hover': {
				backgroundColor: alpha(theme.palette.common.black, 0.10),
			},
			marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(1),
				width: 'auto',
			},
    },
    iconWrapper: {
			padding: theme.spacing(0, 2),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
    },
    inputBase: {
			color: 'inherit',
			'& .MuiInputBase-input': {
				padding: theme.spacing(1, 1, 1, 0),
				// vertical padding + font size from searchIcon
				paddingLeft: `calc(1em + ${theme.spacing(4)})`,
				transition: theme.transitions.create('width'),
				width: '100%',
				[theme.breakpoints.up('sm')]: {
					width: '12ch',
					'&:focus': {
						width: '20ch',
					},
				},
			},
    }
  })
);

const TopBarSearch: React.FC = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <Search />
      </div>
      <InputBase
        className={classes.inputBase}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  )
}

export default TopBarSearch;

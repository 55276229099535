import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, Outlet, useLocation } from 'react-router-dom';
import {
  Tabs,
  Tab
} from '@mui/material';

const Settings = observer(() => {

  const tabs = [
    'general',
    'brands',
    'locations',
    //'users',
    //'billing'
  ];

  const capitalize = ([first, ...rest]: any) =>
    first.toUpperCase() + rest.join('');

  const renderTabs = () => {
    return tabs.map((tab) => (
        <Tab
          key={tab}
          label={capitalize(tab)}
          value={tab}
          component={Link}
          to={`/settings/${tab}`}
        />
      ))
  }

  const location = useLocation();
  const lastSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const val = lastSegment === 'settings' ? 'general' : lastSegment; // Default to 'general' if on '/settings'

  return (
    <div>
      <Tabs variant="fullWidth" value={val}>
        { renderTabs() }
      </Tabs>
      <Outlet />
    </div>
  )
})

export default Settings;


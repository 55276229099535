import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useForm} from 'react-hook-form';
import {useRootProvider} from 'providers/RootContext';
import {Box, CardMedia, Grid, IconButton, Skeleton, Typography,} from '@mui/material';
import {Delete} from '@mui/icons-material';
import {SpacerV, Uploader} from 'components/common';

const CampaginAssets = observer(() => {
  const { businessProvider: bp, campaignProvider: cp, wizardProvider: wp } = useRootProvider();
  const form = useForm({mode: 'onChange'});

  const [useOnlineImage, setUseOnlineImage] = useState(false);
  const [useOnlineVideo, setUseOnlineVideo] = useState(!!cp.ctx.campaign?.video);
  const [canSave, setCanSave] = useState(!!cp.ctx.campaign?.image || !!cp.ctx.campaign?.video);

  useEffect(() => {
    if (canSave) {
      wp.setOnNext(async () => {
        await form.handleSubmit(cp.saveCampaignAssets)();
      });
    } else {
      wp.setOnNext(undefined);
    }
  }, [wp, bp, cp, useOnlineImage, useOnlineVideo, canSave, form, form.formState?.isValid]);

  const onImageUpload = (file: File) => {
    cp.ctx.setCampaignImage(file);
    setCanSave(cp.ctx.hasCampaignImage() || cp.ctx.hasCampaignVideo());
  }

  const onVideoUpload = (file: File) => {
    cp.ctx.setCampaignVideo(file);
    setCanSave(cp.ctx.hasCampaignImage() || cp.ctx.hasCampaignVideo());
  }

  const onImageRemove = () => {
    cp.ctx.setCampaignImage(undefined);
    cp.ctx.campaign.image = '';
    setUseOnlineImage(false);
    setCanSave((cp.ctx.hasCampaignImage() || cp.ctx.hasCampaignVideo()) &&
      (useOnlineImage && useOnlineVideo)
    );
  }

  const onVideoRemove = () => {
    cp.ctx.setCampaignVideo(undefined);
    cp.ctx.campaign.video = '';
    setUseOnlineVideo(false);
    setCanSave(cp.ctx.hasCampaignImage() ||
      cp.ctx.hasCampaignVideo() ||
      (useOnlineImage && useOnlineVideo)
    );
  }

  React.useEffect(() => {
    if (cp.ctx.isEditing) {
      const canUseOnlineImage = !!cp.ctx.campaign?.image;
      setUseOnlineImage(canUseOnlineImage);
    }
  }, [cp, form]);


  return (

    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <div>
        {cp.fetching && (
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />
              <Skeleton variant="rectangular" height={40} width={300} />
              <SpacerV />
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />
              <Skeleton variant="rectangular" sx={{borderRadius: '16px'}} height={200} width={250} />
            </div>
          )
        }

      {!cp.fetching && (
        <div>

          <Typography variant="body1">
            Please upload the image and/or video for the campaign. Either image or video must be uploaded. If both are uploaded, video will take presedence over the image.
          </Typography>
          <SpacerV size="30" />

          {useOnlineImage &&
            <Box sx={{ marginBottom: 2, padding: 2, background: 'rgba(190, 194, 227, 0.15)', borderRadius: 2 }}>
              <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >

                <Grid item xs={9}>
                  <img
                    src={cp.ctx.campaign?.image}
                    alt={cp.ctx.campaign?.name}
                    style={{borderRadius: 24, maxWidth: '600px', maxHeight: '600px', objectFit: 'scale-down'}}
                  />
                </Grid>

                <Grid item xs={3} sx={{textAlign: 'center'}}>
                  <IconButton
                    onClick={onImageRemove}
                    color="error"
                    sx={{align: 'right'}}
                  >
                    <Delete />
                  </IconButton>

                </Grid>
              </Grid>
            </Box>
          }

          {!useOnlineImage &&
            <div>
              <Typography variant="body2">
                For best user experience the image should be in portrait mode and with 1290x2796 px resolution.
              </Typography>
              <Uploader isImage onSelect={onImageUpload} />
              <SpacerV size="30" />
            </div>
          }

          { useOnlineVideo &&
              (
                <Box sx={{ padding: 2, background: 'rgba(190, 194, 227, 0.15)', borderRadius: 2 }}>
                  <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >

                    <Grid item xs={9}>
                      <CardMedia component="video"
                        image={cp.ctx.campaign?.video}
                        autoPlay
                        sx={{borderRadius: 2}}
                      />
                    </Grid>

                    <Grid item xs={3} sx={{textAlign: 'center'}}>
                      <IconButton
                        onClick={onVideoRemove}
                        color="error"
                        sx={{align: 'right'}}
                      >
                        <Delete />
                      </IconButton>

                    </Grid>
                  </Grid>
                </Box>
              )
          }

          {!useOnlineVideo && (
            <div>
              <Typography variant="body2">
                For best performance and experience the video should be in portrait mode optimized for mobile phones and cannot exceed duration more than 8 seconds.
              </Typography>
              <Uploader onSelect={onVideoUpload} />
            </div>
          )}

        </div>
        )
      }
      </div>
    </Box>
  );
})

export default CampaginAssets;

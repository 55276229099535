import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useRootProvider } from 'providers/RootContext';
import { useForm } from 'react-hook-form';
import {
  Typography
} from '@mui/material';
import SpacerV from 'components/common/SpacerV';
import YTextField from 'components/common/YTextField';
import YGoogleAddressAutocomplete, { AddressData } from 'components/common/YGoogleAddressAutocomplete';
import YButtonPrimary from 'components/common/YButtonPrimary';

const useStyles: Function = makeStyles((theme: Theme) => createStyles({
  root: {
    textAlign: 'center'
  },
  title: {
    color: theme.palette.text.secondary,
  }
}));

interface Props {}

function Company(props: Props) {
  const classes = useStyles();
  const { businessProvider } = useRootProvider();

  const defaultValues = {
    name: '',
    ein: '',
    address: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    lat: '',
    lng: '',
    place: '',
  }

  const { handleSubmit, setValue, control } = useForm({ defaultValues });
  
  /**
   * Retrieves data from google places
   * @param data Parsed place data
   */
  const onPlaceSelect = (data: AddressData) => {
    if(data.street || data.streetNumber){ 
      setValue("address", `${data.streetNumber} ${data.street}`)
    }
    if(data.city){
      setValue("city", data.city);
    }
    if(data.zip){
      setValue("zip", data.zip);
    }
    if(data.state) {
      setValue('state', data.state);
    }
    if(data.country) {
      setValue('country', data.country);
    }
    if(data.lat) {
      setValue('lat', data.lat);
    }
    if(data.lng) {
      setValue('lng', data.lng);
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Welcome
      </Typography>
      <SpacerV size="small" />

      <Typography variant="body1" >
        If you own a restaurant, start by providing your company info.
      </Typography>
      <SpacerV />

      <YTextField
        fullWidth
        label="Company Name"
        control={control}
        isRequired
        name="name"
      />

      <YTextField
        fullWidth
        label="EIN Number"
        control={control}
        minLength={9}
        maxLength={9}
        isRequired
        name="ein"
      />

      <YGoogleAddressAutocomplete
        name="place"
        label="Company address"
        control={control}
        onPlaceSelect={onPlaceSelect}
        isRequired
      />

      <SpacerV size="small"/>

      <YButtonPrimary fullWidth onPress={handleSubmit(businessProvider.enteredCompanyName)}>
        Continue
      </YButtonPrimary>

      <SpacerV size="large"/>

    </div>
  );
}

export default Company;


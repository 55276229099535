import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootProvider } from 'providers/RootContext';
import { createStyles, makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import InfoPanel from '../../components/common/InfoPanel';
import InfoPanelItem from '../../components/common/InfoPanelItem';
import TextField from "@mui/material/TextField";
import Api from 'api/api';
import { YCard } from 'components/common/YCard';
import YGrid from 'components/common/YGrid';

const useStyles: Function = makeStyles((theme) =>
    createStyles({
        formDrawerInput: {
            marginTop: 24,
        },
    })
);

export const OrderDetails = observer(() => {
    const { orderProvider: op, drawerProvider: dp } = useRootProvider();

    let { id } = useParams();
    const [order, setOrder] = useState<any>();

    const classes = useStyles();

    useEffect(() => {
        op.getOrder(id).then((data) => {
            setOrder(data);
        });
    }, [id, op]);

    let columns = [
        { field: 'name', headerName: 'Name', flex: 0.3 },
        { field: 'type', headerName: 'Type', flex: 0.3 },
        { field: 'price', headerName: 'Price', flex: 0.2 },
        { field: 'quantity', headerName: 'Quantity', flex: 0.2 },
        { field: 'taxRate', headerName: 'Tax Rate', flex: 0.2 },
        { field: 'amount', headerName: 'Amount', flex: 0.2,},
    ];

    const saveOrderCallback = () => {
        Api.order.update(order!.id, dp.values).then(() => {
          // @ts-ignore
            dp.getOrders().then(() => {
              // @ts-ignore
                setOrder(dp.getOrder(id));
          });
        });
        dp.close();
        dp.reset();
    }

    const editOrderInfo = () => {
        dp.drawerOpen(
          <div>
              <div key={"reference"} className={classes.formDrawerInput!}>
                  <TextField
                    label={"Reference"}
                    fullWidth
                    autoComplete="new-password"
                    defaultValue={dp.values.name || ''}
                    onChange={(e) => {
                        let newFormValues = {...dp.values, "reference": e.target.value};
                        dp.setValues(newFormValues);
                    }}
                  />
              </div>

              <div key={"provider"} className={classes.formDrawerInput!}>
                  <TextField
                    label={"Provider"}
                    fullWidth
                    autoComplete="new-password"
                    defaultValue={dp.values.description || ''}
                    onChange={(e) => {
                        let newFormValues = {...dp.values, "provider": e.target.value};
                        dp.setValues(newFormValues);
                    }}
                  />
              </div>
          </div>,
          "Edit campaign info",
        );
        dp.setAction(saveOrderCallback)
    }

    const editPaymentInfo = () => {
        dp.drawerOpen(<div>TODO</div>, 'Edit payment Info');
        console.log('edit payment info');
    }

    const editCustomerInfo = () => {
        dp.drawerOpen(<div>TODO</div>, 'Edit customer Info');
        console.log('edit customer info');
    }

    return (
        <div>
            <div style={{width: "100%", display: "flex"}}>
                <div style={{width: '48%', marginRight: '1em'}}>
                    <div style={{ marginBottom: '1em' }}>
                        <InfoPanel title={'Order info'} onEdit={() => editOrderInfo()}>
                            <InfoPanelItem label={'Reference'} value={order?.reference || ''} />
                            <InfoPanelItem label={'Provider'} value={order?.provider || ''} />
                            <InfoPanelItem label={'Provider ID'} value={order?.providerId || ''} />
                            <InfoPanelItem label={'Type'} value={order?.type || ''} />
                            <InfoPanelItem label={'Status'} value={order?.status || ''} />
                            <InfoPanelItem label={'Created On'} value={order?.createdOn || ''} />
                            <InfoPanelItem label={'Scheduled For'} value={order?.scheduledFor || ''} />
                        </InfoPanel>
                    </div>

                    <div style={{ marginBottom: '1em' }}>
                        <InfoPanel title={'Payment info'} onEdit={() => editPaymentInfo()}>
                            <InfoPanelItem label={'Type'} value={order?.type || ''} />
                            <InfoPanelItem label={'Reference'} value={order?.reference || ''} />
                            <InfoPanelItem label={'Total'} value={order?.totalAmount?.formatted || ''} />
                        </InfoPanel>
                    </div>
                </div>
                <div style={{width: '48%'}}>
                    <div style={{ marginBottom: '1em' }}>
                        <InfoPanel title={'Customer info'} onEdit={() => editCustomerInfo()}>
                            <InfoPanelItem label={'ID'} value={order?.customer?.id || ''} />
                            <InfoPanelItem label={'Name'} value={order?.customer?.name || ''} />
                            <InfoPanelItem label={'Address'} value={order?.customer?.address || ''} />
                            <InfoPanelItem label={'Email'} value={order?.customer?.email || ''} />
                            <InfoPanelItem label={'Phone'} value={order?.customer?.phone || ''} />
                            <InfoPanelItem label={'Note'} value={order?.customer?.note || ''} />
                        </InfoPanel>
                    </div>

                    <div style={{ marginBottom: '1em' }}>
                        <InfoPanel title={'Redeemed prize'}>
                            <InfoPanelItem label={'Name'} value={order?.redeemedPrize?.name || ''} />
                            <InfoPanelItem label={'Type'} value={order?.redeemedPrize?.type || ''} />
                            <InfoPanelItem label={'Min Order'} value={order?.redeemedPrize?.minOrder || ''} />
                            <InfoPanelItem label={'Free Item'} value={order?.redeemedPrize?.freeItem || ''} />
                        </InfoPanel>
                    </div>
                </div>
            </div>
            <YCard>
                <YGrid
                    columns={columns}
                    rows={[]}
                />
            </YCard>
        </div>
    );
});
import React, { useState, useEffect } from 'react';
import {
  Box,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  YButtonPrimary,
  YTextField,
} from '../../../components/common';
import { useRootProvider } from '../../../providers/RootContext';
import YGoogleAddressAutocomplete, { AddressData } from '../../../components/common/YGoogleAddressAutocomplete';
import InputMask from "react-input-mask";
import {Controller} from "react-hook-form";
import {observer} from "mobx-react";

interface Props {
  form: any;
  setValue: any;
  onAction: any;
  initialAddress?: string;  // New prop for initial address
}

const CreateLocation = observer( (props: Props) => {
  const { businessProvider: bp } = useRootProvider();
  const { form, setValue, initialAddress } = props;  // Destructure new prop
  const [ deliveryEnabled, setDeliveryEnabled ] = useState(form.getValues('deliveryEnabled') || false);
  const [ googleAddress, setGoogleAddress ] = useState(initialAddress || '');  // Set initial value

  useEffect(() => {
    setValue('deliveryEnabled', deliveryEnabled);

    if (initialAddress) {
      setValue('place', initialAddress, { shouldValidate: true });
    }

  }, [deliveryEnabled, setValue, initialAddress, form]);

  /**
   * Retrieves data from google places
   * @param data Parsed place data
   */
  const onPlaceSelect = (data: AddressData) => {
    setGoogleAddress(`${data.streetNumber} ${data.street}`);
    if (data.street || data.streetNumber) {
      setValue('address', `${data.streetNumber} ${data.street}`);
    }
    if (data.city) {
      setValue('city', data.city);
    }
    if (data.zip) {
      setValue('zip', data.zip);
    }
    if (data.state) {
      setValue('state', data.state);
    }
    if (data.country) {
      setValue('country', data.country);
    }
    if (data.lat) {
      setValue('latitude', data.lat);
    }
    if (data.lng) {
      setValue('longitude', data.lng);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <div>
        <YTextField
          name="brandId"
          label="Brand"
          control={form.control}
          select
          fullWidth
          isRequired
        >
          {bp.brands && bp.brands.map((b) => (
            <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
          ))}
        </YTextField>

        <YTextField
          fullWidth
          name="name"
          label="Name"
          control={form.control}
          isRequired
        />
        <YTextField
          fullWidth
          name="email"
          label="Email"
          isEmail
          control={form.control}
          isRequired
        />


        <Controller
          name="phone"
          control={form.control}
          rules={{
            validate: value => value.replace(/[^\d]/g, '').length === 11 || 'Please enter a full phone number.',
          }}
          render={({ field }) => (
            <InputMask mask="+1(999) 999-9999" value={field.value} onChange={field.onChange}>
              {
                // @ts-ignore
                (inputProps: any) => <YTextField
                  fullWidth
                  name="phone"
                  label="Phone"
                  control={form.control}
                  isRequired
                />
              }
            </InputMask>
          )}
        />

        <YGoogleAddressAutocomplete
          name="place"
          label="Location address"
          control={form.control}
          onPlaceSelect={onPlaceSelect}
          isRequired
          initialValue={googleAddress}  // Pass the initial value here
        />

        {/*<FormControlLabel
          control={
            <Checkbox
              checked={deliveryEnabled}
              onChange={(e) => setDeliveryEnabled(e.target.checked)}
              disableRipple
            />
            }
          label="This location provides delivery"
        />

        {deliveryEnabled &&
          <YTextField
            fullWidth
            name="deliveryFee"
            label="Delivery Fee"
            control={form.control}
            isRequired={deliveryEnabled}
          />

        }

        {deliveryEnabled &&
          <YTextField
            fullWidth
            name="deliveryRadius"
            label="Delivery Radius"
            control={form.control}
            isRequired={deliveryEnabled}
          />
        }*/}

      </div>
      <Box >
        <YButtonPrimary
          fullWidth
          onPress={form.handleSubmit(props.onAction)}
          disabled={form.formState.isValid}
          loading={bp.isFetching}
        >
          Save
        </YButtonPrimary>
      </Box>
    </Box>
  );
});

export default CreateLocation;

import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import RootProvider from './RootProvider';

let provider: RootProvider;

const ProviderContext = createContext<RootProvider | undefined>(undefined);

export const RootContextProvider = ({ children }: { children: React.ReactNode }) => {

  const navigate = useNavigate();
  provider = provider ?? new RootProvider(navigate);
  (window as any).provider = provider;

  return <ProviderContext.Provider value={provider}>{children}</ProviderContext.Provider>
}

export const useRootProvider = () => {
  const context = useContext(ProviderContext);
  if (context === undefined) {
    throw new Error('useRootProvider must be used within RootContextProvider');
  }

  return context;
}

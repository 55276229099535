import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useRootProvider } from 'providers/RootContext';
import { useForm } from 'react-hook-form';
import {
  Typography
} from '@mui/material';
import SpacerV from 'components/common/SpacerV';
import YTextField from 'components/common/YTextField';
import YButtonPrimary from 'components/common/YButtonPrimary';
import {useNavigate} from "react-router-dom";

const useStyles: Function = makeStyles((theme: Theme) => createStyles({
  root: {
    textAlign: 'center'
  },
  title: {
    color: theme.palette.text.secondary,
  }
}));

function NewPassword() {
  const classes = useStyles();
  const { userProvider } = useRootProvider();
  const navigate = useNavigate();

  const defaultValues = {
    password: '',
    confirmPassword: ''
  }

  const {control, getValues } = useForm({ defaultValues });

  const handleNewPassword = async () => {
    try {
      const { password, confirmPassword } = getValues();
      console.log('setting password: email:', userProvider.email, ', code', userProvider.code, ', password', password, ', confirmPassword', confirmPassword);
      await userProvider!.resetPassword(userProvider.email, userProvider.code, password);
      userProvider.forgotPasswordFlow = false;
      userProvider.email = '';
      userProvider.code = '';
      navigate('/sign-in');
    } catch (e) {
      console.log(e);
    }
  }

  if (!userProvider.email || !userProvider.code) {
    navigate('/sign-in');
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        New Password
      </Typography>
      <SpacerV size="small" />

      <Typography variant="body1" >
        Enter and confirm your password
      </Typography>
      <SpacerV />

      <YTextField
        fullWidth
        name="password"
        type="password"
        label="New Password"
        control={control}
        isRequired
      />

      <YTextField
        fullWidth
        name="confirmPassword"
        type="password"
        label="Confirm Password"
        control={control}
        isRequired
      />

      <SpacerV size="small"/>

      <YButtonPrimary fullWidth onPress={handleNewPassword} >
        Save
      </YButtonPrimary>

      <SpacerV size="large"/>
    </div>
  );
}

export default NewPassword;
